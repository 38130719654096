import React from "react";
import JobCard from "./Jobcard";

function Jobcardlist({ jobs, setdesc, form, setform, client, salary }) {
  return (
    <div id="jobslist" className="jobs w-100 flex center flex-column ma-2">
      {
        // jobs === undefined || jobs.length<=0 ?<div style={{height:"50vh"}} className='flex justify-center items-center'><p className='ma0 f6-l f6-m f7 gray tc'>{salary?'':'No jobs matched your skills yet, please be patient'}</p></div>:jobs.map((jobdata,id) =><JobCard job={jobdata} setdesc={setdesc} form={form} setform={setform} client={client} salary={salary} k={id} key={id}/>)

        jobs === undefined || jobs.length <= 0
          ? ""
          : jobs.map((jobdata, id) => (
              <JobCard
                job={jobdata}
                setdesc={setdesc}
                form={form}
                setform={setform}
                client={client}
                salary={salary}
                k={id}
                key={id}
              />
            ))
      }
    </div>
  );
}

export default Jobcardlist;

// Your Application has not been viewed yet
