import React, { useState, useEffect } from "react";
import "tachyons";
import "../../styles/dashboard.scss";
import Jobcards from "../JobCards/Jobcardlist";
// import { goToURL } from '../../util/helpers/helper-methods';
import { Snackbar, Grid, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { sendRequest } from "../../util/helpers/helper-methods";
import Loading from "../Shared/Loading";
import ErrorPage from "../Shared/ErrorPageDashboard";
import sanitizeHtml from "sanitize-html-react";

export default function AppliedJobs({ id }) {
  const [salary, setsalary] = useState(true);
  const [description, setdescription] = useState({
    desc: "",
    code: "",
    id: "",
  });
  const [form, setform] = useState([]);
  const [read, setread] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [error, seterror] = useState(false);
  const [joblist, setjoblist] = useState([]);
  const [withdrawn, setwithdrawn] = useState(false);
  const [popup, setpopup] = useState(false);
  const [severity, setseverity] = useState("");
  const [message, setmessage] = useState("");

  const handleWithdraw = () => {
    // console.log(description.id)
    sendRequest("/jobs/withdraw", "POST", { id: id, jobId: description.id })
      .then((data) => {
        // console.log(data)
        if (data.success) {
          setmessage("Withdrawn Successfully");
          setseverity("error");
          setpopup(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log("clicked");
  };

  const checkwithdrawn = (c_id, j_id) => {
    sendRequest("/jobs/checkwithdrawn", "POST", {
      candidate_id: c_id,
      jobId: j_id,
    })
      .then((data) => {
        if (!data.success) {
          setwithdrawn(true);
        } else {
          setwithdrawn(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log("checked");
  };

  useEffect(() => {
    if (id === "") return;
    setisloading(true);
    sendRequest("/jobs/appliedjobs", "POST", { candidate_id: id })
      .then((data) => {
        if (data.success) {
          // console.log(data.message)
          data.message.map((job) =>
            // console.log(job)
            sendRequest("/jobs/getjobwithjobid", "POST", { jobId: job })
              .then((data) => {
                if (data.success) {
                  // console.log(data.message)
                  const val = {
                    jobtype: "Applied",
                    id: data.message._id,
                    title: data.message.jobTitle,
                    location: {
                      city: data.message.city,
                      country: data.message.country,
                    },
                    description: data.message.description,
                    salary: data.message.from + "-" + data.message.to + "€",
                    jobcode: data.message.requirements,
                  };
                  setjoblist((joblist) => [...joblist, val]);
                  setsalary(true);
                }
                if (data.message.length === 0) {
                  setdescription({ desc: "" });
                } else {
                  document.getElementById("jobslist").firstChild.click();
                }
              })
              .catch((err) => {
                console.log(err);
                setisloading(false);
                seterror(true);
              }),
          );
          setisloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
        seterror(true);
      }); // eslint-disable-next-line
  }, []);

  if (error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={seterror} />
        </div>
      </React.Fragment>
    );
  }

  if (isloading) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="Loading Jobs..." />
      </div>
    );
  }

  return (
    // <div className="ma4-l ma4-m ma">
    <div>
      <div
        onClick={() => setread(false)}
        className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
          read ? "act" : ""
        }`}
      ></div>
      {popup ? (
        <Snackbar
          open={popup}
          autoHideDuration={4000}
          onClose={() => {
            setpopup(false);
          }}
        >
          <Alert
            severity={severity}
            onClose={() => {
              setpopup(false);
            }}
            variant="filled"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="flex flex-row-l flex-column-m flex-column justify-around mt4">
        {joblist.length === 0 ? (
          ""
        ) : (
          <div className={` flex-1 ph2 mr2 w-100`}>
            <Jobcards
              jobs={joblist}
              setdesc={setdescription}
              form={form}
              setform={setform}
              salary={salary}
            />
          </div>
        )}
        <div
          className={` flex-2  br2 bg-white pa4 ml1 mt0-l mt3-m mt3 w-100 flex justify-center items-start`}
        >
          {description.desc === "" ? (
            <p className="gray tc f5-l f5-m f6">No jobs to show.</p>
          ) : (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} className="mb1">
                <Typography color="textSecondary" variant="h6" align="center">
                  Job Description
                </Typography>
              </Grid>
              <Grid item xs={12} className="flex justify-center items-center">
                {/* {apply?'':<CheckCircleIcon className="pl2"/>} */}
                {checkwithdrawn(id, description.id) ? (
                  ""
                ) : (
                  <button
                    onClick={handleWithdraw}
                    style={{ background: "#6EB6FF" }}
                    className={`${
                      withdrawn ? "c-shadow h2 pointer dim " : "withdrawn"
                    }  w6-l w5 h7-mo f8-mo f7-m f7-l mr2 bn br2 ph3 pv2 dib white flex-l justify-center items-center`}
                    disabled={!withdrawn}
                  >
                    {withdrawn ? "Withdraw" : "Withdrawn"}
                  </button>
                )}
              </Grid>
              <Grid container spacing={1}>
                {description?.desc && (
                  <div>
                    <p className="b gray f4-l f5">Job Description</p>
                    <div
                      className="gray f5-l f6"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(description?.desc),
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}
