import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import "../styles/candidate.scss";
import "tachyons";
import ToggleButton from "react-toggle-button";
import "../styles/setting.scss";
import { sendRequest } from "../util/helpers/helper-methods";
import ErrorPage from "../components/Shared/ErrorPageDashboard";
import ResetPassword from "../components/Settings/ResetPassword";

function Settings({ id, email, setloggedin }) {
  const [general_settings, setgeneral_settings] = useState(true);
  const [toggle, settoggle] = useState(false);

  const [deleteaccount, setdeleteaccount] = useState(false);
  const [confirmdelete, setconfirmdelete] = useState(false);

  const [matching, setmatching] = useState(false);
  const [moclicked, setmoclicked] = useState(false);

  const [name, setname] = useState("");
  const [Error, setError] = useState(false);

  useEffect(() => {
    if (id === "") return;
    sendRequest("/momatchflag/status", "POST", {
      candidate_id: id,
    })
      .then((data) => {
        if (data.success) {
          if (data.momatchflag === null) setError(true);
          else setmatching(data.momatchflag);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [id]);

  useEffect(() => {
    if (id === "") return;
    sendRequest("/getuser", "POST", {
      candidate_id: id,
    })
      .then((data) => {
        if (data.length > 0) {
          setname(data[0].firstname + " " + data[0].lastname);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [id]);

  useEffect(() => {
    if (!moclicked) return;
    sendRequest("/momatchflag", "POST", {
      candidate_id: id,
      data: matching,
    }).then((data) => {
      if (data.success) {
        setmoclicked(false);
      }
    });
  }, [matching, id, moclicked]);

  useEffect(() => {
    if (deleteaccount && confirmdelete) {
      sendRequest("/delete", "POST", {
        candidate_id: id,
        email: email,
        timestamp: new Date(),
      }).then((data) => {
        if (data.success) {
          for (let i = 0; i <= 30; i++) {
            localStorage.removeItem(i);
          }
          setloggedin(false);
          localStorage.removeItem("email");
          localStorage.removeItem("id");
          localStorage.removeItem("loggedin");
        }
      });
    } // eslint-disable-next-line
  }, [deleteaccount, confirmdelete]);

  if (Error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={setError} />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div style={{ background: "#eef2f5" }} className="flex-1 w-100">
      <div className="w-90 center">
        <p className="gray tc f2 mb0">Settings</p>
        <div
          style={{ borderColor: "rgb(249, 246, 246)" }}
          className="flex self-start justify-start pt4 ml2 pb1"
        >
          <Button
            onClick={() => setgeneral_settings(true)}
            variant="contained"
            className={`cbtn ${general_settings ? "cbtn-active" : ""}`}
          >
            General
          </Button>
          <Button
            onClick={() => setgeneral_settings(false)}
            variant="contained"
            className={`cbtn ${!general_settings ? "cbtn-active" : ""}`}
          >
            Credentials
          </Button>
        </div>
        {general_settings ? (
          // General settings
          <div className="mv4 pa4 w-95 center flex-1 tc bg-white">
            <div
              className={`${
                deleteaccount ? "" : "hide"
              } fixed z-10 top-0 bottom-0 left-0 right-0 flex justify-center items-center`}
            >
              <div className="h5 w-50 flex justify-center items-center bg-white c-shadow br2">
                <div className="w-100">
                  <p className="gray f5-l f6-m f7">
                    All your data will be deleted. Are you Sure?
                  </p>
                  <div className="flex center justify-around w-80">
                    <Button
                      onClick={() => setconfirmdelete(true)}
                      className="dim flex-1"
                      variant="outlined"
                      style={{ borderColor: "red", color: "red" }}
                    >
                      Confirm Delete
                    </Button>
                    <div className="w2"></div>
                    <Button
                      onClick={() => setdeleteaccount(false)}
                      className="dim flex-1"
                      variant="outlined"
                      style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <p className="gray tc f3">General Settings</p>
            {!deleteaccount && (
              <div className="flex flex-column items-center justify-center">
                <div className="flex items-center mt4 justify-between w-50">
                  <p className="gray f5-l f6-m f7 ma0 mr3">
                    Turn {toggle ? "off" : "on"} notification&apos;s ?
                  </p>
                  <div className="toggle-btn">
                    <ToggleButton
                      value={toggle}
                      onToggle={() => {
                        settoggle(!toggle);
                      }}
                    />
                  </div>
                </div>
                <div className="mt4 flex justify-between w-50 items-center">
                  <p className="gray f5-l f6-m f7 ma0 mr3">
                    Disable Job Searching
                  </p>
                  <Button
                    onClick={() => {
                      setmoclicked(true);
                      setmatching((f) => !f);
                    }}
                    className="dim"
                    variant="outlined"
                    style={{ borderColor: "#6eb6ff", color: "#6eb6ff" }}
                  >{`${matching ? "Stop" : "Start"} Matching`}</Button>
                </div>
                <div className="mt4 flex justify-between w-50 items-center">
                  <p className="gray f5-l f6-m f7 ma0 mr3">Request Your Data</p>
                  <a
                    href={`mailto:talent@moyyn.com?subject=Request Data - Candidate&body=Hello,%0d%0a %0d%0a I , as a candidate of Moyyn, %0d%0a would like to request my data stored in Moyyn. %0d%0a %0d%0a Best regards, %0d%0a ${name}`}
                  >
                    <Button
                      className="dim"
                      variant="outlined"
                      style={{ borderColor: "green", color: "green" }}
                    >
                      Request Data
                    </Button>
                  </a>
                </div>
                <div className="mt4 flex justify-between w-50 items-center">
                  <p className="gray f5-l f6-m f7 ma0 mr3">
                    Delete Your Account
                  </p>
                  <Button
                    onClick={() => setdeleteaccount(true)}
                    className="dim"
                    variant="outlined"
                    style={{ borderColor: "red", color: "red" }}
                  >
                    Delete Account
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          // :edit_company?
          // //credentials
          <div className="mv4 pa3 w-95 center flex-1 tc bg-white">
            <p className="gray tc f3">Credentials</p>
            <ResetPassword id={id} email={email} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings;
