import React, { useState, useEffect } from "react";
import { checkFormComplete, sendRequest } from "../util/helpers/helper-methods";
import { url } from "../util/data/base-url";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { initialValues } from "../util/data/initial-values";
import PageOne from "../components/FormPages/PageOne";
import PageTwo from "../components/FormPages/PageTwo";
import ErrorPage from "../components/Shared/ErrorPage";
import Loading from "../components/Shared/Loading";
import "./../styles/CandidatesForm.scss";
import "tachyons";
import "../components/FormPages/toggle.css";
import Footer from "../components/Footer/footer";
import FormImage2 from "../Svg/FormImage2";

const Form = ({
  setEmail,
  setid,
  editform = false,
  initialdata,
  openApply = false,
  setloggedin,
  referral,
  query,
}) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const [submitTrigger, setSubmitTrigger] = useState(false);
  const { search } = useLocation();
  const [jobData, setJobData] = useState(null);
  const [formPage, setFormPage] = useState(0);
  const params = useParams();
  const { jobName } = params;

  useEffect(() => {
    if (editform) {
      history.push("editprofile");
      setFormValues(initialdata);
      setFormPage(1);
    } // eslint-disable-next-line
    if (openApply && search) {
      const paramJobId = search.replace("?jobId=", "");
      sendRequest("/jobs/getjobwithjobid", "POST", { jobId: paramJobId })
        .then((data) => {
          if (data.success) {
            setJobData(data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [initialdata]);

  const moveToPage = (part) => {
    if (part === 3) setFormPage(0);
    else setFormPage(1);
  };

  const handleFormChange = (
    values,
    part,
    next = false,
    complete = true,
    submit = false,
  ) => {
    if (complete) {
      setFormValues((prevState) => {
        const newState = [...prevState];
        newState[part] = { ...values, Complete: true };
        return newState;
      });
    } else {
      setFormValues((prevState) => {
        const newState = [...prevState];
        newState[part] = { ...values };

        return newState;
      });
    }

    if (next) {
      moveToPage(part);
    }

    if (submit) {
      setSubmitTrigger(true);
    }
  };

  const applyToJob = (candidateId) => {
    if (!candidateId && !jobData && !jobName) return;
    setIsLoading(true);
    sendRequest("/jobs/applyforjob", "POST", {
      candidate_id: candidateId,
      jobId: jobData._id,
    })
      .then((data) => {
        if (data.success && data.message !== "No such user with this user id") {
          history.push(`/applied/${jobName}`);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setIsLoading(false);
  };

  const submitForm = () => {
    const form = [...formValues];

    const fileName = `${form[0].email}`;
    let file = null;
    let file2 = null;

    if (form[1].cvEnglish.length > 0)
      file = form[1].cvEnglish[0].data.replace(
        /^data:application\/pdf;base64,/,
        "",
      );
    if (form[1].cvGerman.length > 0)
      file2 = form[1].cvGerman[0].data.replace(
        /^data:application\/pdf;base64,/,
        "",
      );
    form[1].cvEnglish = { data: file, fileName: fileName + "_english.pdf" };
    form[1].cvGerman = { data: file2, fileName: fileName + "_german.pdf" };
    form[0].email = form[0].email.toLowerCase();

    const timeout = setTimeout(() => {
      history.push("/candidate/partner");
    }, 1000 * 120);

    fetch(`${url}/${editform ? "editprofile" : "register"}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(form),
    })
      .then((res) => res.json())
      .then((data) => {
        clearTimeout(timeout);
        if (data.success) {
          if (!editform) {
            setEmail(form[0].email);
            setid(data.candidate_id);
            setloggedin(true);
            localStorage.setItem("loggedin", JSON.stringify(true));
            localStorage.setItem("email", JSON.stringify(form[0].email));
            localStorage.setItem("id", JSON.stringify(data.candidate_id));
            if (openApply) applyToJob(data.candidate_id);
          }
        }
        setIsLoading(false);
        history.push("/dashboard");
      })
      .catch(() => {
        clearTimeout(timeout);
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  });

  useEffect(() => {
    const [isComplete] = checkFormComplete(formValues);
    document.body.style.zoom = "90%";

    if (isComplete) {
      if (submitTrigger) {
        setIsLoading(true);
        submitForm();
      }
    }
    // eslint-disable-next-line
  }, [submitTrigger]);

  const formComplete = () => {
    const [isComplete, missingParts] = checkFormComplete(formValues);
    return [isComplete, missingParts];
  };

  const handleError = () => {
    setFormValues(initialValues);
    history.push("/application");
    setError(false);
  };

  if (error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={handleError} />
        </div>
      </React.Fragment>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="AI matchmaking in progress..." />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <div
          className="flex flex-row-l flex-column-m flex-column items-center items-start-l justify-around ph5-l pv4-l pa2-m pa2 bg-white"
          style={{
            minHeight:
              window.location.pathname === "/application" ||
              window.location.pathname === "/application/"
                ? ""
                : "100vh",
          }}
        >
          {formPage !== 1 ? (
            <div className="mt0-l mt2-m mt2 mr5-l mr5-m mr0">
              <PageOne
                initialValues={formValues[0]}
                handleFormChange={handleFormChange}
                openApply={openApply}
                jobData={jobData}
              />
            </div>
          ) : (
            <>
              <div className={`ml5 mr5 mt3`}>
                <FormImage2 />
              </div>
              <div className="mt0-l mt2-m mt2 mr5-l mr5-m mr0">
                <PageTwo
                  initialValues={formValues[1]}
                  handleFormChange={handleFormChange}
                  editform={editform}
                  referral={referral}
                  query={query}
                  formComplete={formComplete}
                />
              </div>
            </>
          )}
        </div>
        <div
          className={`${
            window.location.pathname === "/application" ||
            window.location.pathname === "/application/"
              ? ""
              : "hide"
          }`}
        >
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Form;
