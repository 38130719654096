import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { sendRequest } from "../../util/helpers/helper-methods";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Link,
  FormHelperText,
  useMediaQuery,
} from "@material-ui/core";
import ResetPass from "../ForgotPass/UpdatePass";

const EnterEmail = ({
  email,
  setEmail,
  setError,
  setIsLoading,
  id,
  setid,
  setloggedin,
}) => {
  const [name, setName] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);
  const [pass, setpass] = useState("");
  const [resetpassflag, setresetpassflag] = useState(false);

  const screenAtSmall = useMediaQuery("(max-width:600px)");
  const history = useHistory();

  const handleEmailChange = (event) => {
    if (isNotFound) {
      setIsNotFound(false);
    }
    setEmail(event.target.value.toLowerCase());
  };

  const handleRequest = () => {
    sendRequest("/login", "POST", { email: email, password: pass })
      .then((data) => {
        if (data.success) {
          if (!data.resetpassFlag) {
            setEmail(data.email);
            setName(data.name);
            setid(data.candidate_id);
            setloggedin(true);
            localStorage.setItem("loggedin", JSON.stringify(true));
            localStorage.setItem("email", JSON.stringify(email));
            localStorage.setItem("id", JSON.stringify(data.candidate_id));
          } else {
            setresetpassflag(true);
          }
        } else {
          setIsNotFound(true);
        }
      })
      .catch(() => setError(true));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRequest();
    }
  };

  const handleNameApprove = () => {
    setIsLoading(true);
    sendRequest("/application", "POST", { email })
      .then((data) => {
        if (!data.success) {
          setIsLoading(false);
          history.push("/dashboard");
        } else {
          history.push("/candidate/partners");
        }
      })
      .catch(() => setError(true));
  };
  return (
    <React.Fragment>
      <Grid container spacing={7}>
        {!name && !resetpassflag && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography color="textSecondary" align="center">
                Check for new job matches
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      width="80%"
                      display="flex"
                      marginBottom="2rem"
                      justifyContent="center"
                    >
                      <TextField
                        value={email}
                        onChange={handleEmailChange}
                        onKeyDown={handleKeyDown}
                        variant="outlined"
                        label="Enter email address"
                        inputProps={{
                          style: { width: "30rem", paddingBottom: "2rem" },
                        }}
                      />
                    </Box>
                    <Box width="80%" display="flex" justifyContent="center">
                      <TextField
                        value={pass}
                        onChange={(e) => setpass(e.target.value)}
                        onKeyDown={handleKeyDown}
                        variant="outlined"
                        label="Enter password"
                        type="password"
                        inputProps={{
                          style: { width: "30rem", paddingBottom: "2rem" },
                        }}
                      />
                    </Box>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      style={{ marginTop: "1.5rem" }}
                      align="left"
                    >
                      Forgot You Password? Click{" "}
                      <Link
                        onClick={() => history.push("/forgotpass")}
                        style={{ cursor: "pointer", color: "#6EB6FF" }}
                      >
                        {" "}
                        here
                      </Link>
                    </Typography>
                  </Box>
                  <div className="flex justify-center items-center mt4">
                    <div className="w-50 flex justify-center items-center">
                      <Button
                        className="moyynButton--main mb4"
                        onClick={handleRequest}
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </Grid>

                {isNotFound && (
                  <Grid item xs={12}>
                    <Box width="100%" display="flex" justifyContent="center">
                      <FormHelperText style={{ color: "red" }}>
                        *Wrong email or password
                      </FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                New candidate? Click{" "}
                <Link
                  onClick={() => history.push("/application")}
                  style={{ cursor: "pointer", color: "#6EB6FF" }}
                >
                  {" "}
                  here
                </Link>
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        {name && !resetpassflag && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary" align="center">
                Are you {name}?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box width="100%" display="flex" justifyContent="center">
                <Button
                  onClick={() => setName("")}
                  style={{ marginRight: "1rem", textTransform: "none" }}
                  className="moyynButton--secondary"
                >
                  {screenAtSmall ? "No" : "No, let me try again"}
                </Button>
                <Button
                  onClick={() => handleNameApprove()}
                  style={{ marginLeft: "1rem", textTransform: "none" }}
                  className="moyynButton--main"
                >
                  {screenAtSmall ? "Yes" : "Yes, let's continue"}
                </Button>
              </Box>
            </Grid>
          </React.Fragment>
        )}
        {resetpassflag && (
          <div className="w-100">
            <ResetPass
              setloggedin={setloggedin}
              setid={setid}
              setEmail={setEmail}
              email={email}
            />
          </div>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default EnterEmail;
