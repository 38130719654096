import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { sendRequest } from "../util/helpers/helper-methods";
import ReferralList from "../components/ReferralElements/ReferralList";
import Loading from "../components/Shared/Loading";
import ErrorPage from "../components/Shared/ErrorPageDashboard";
import ReferralURL from "../components/ReferralElements/ReferralCode";
import ReferralStatistics from "../components/ReferralElements/ReferralStatistics";

function ReferralDashboard({ id, email }) {
  const [referralList, setReferralList] = useState([]);
  const [error, setError] = useState(false);
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    if (id === "") return;
    setLoading(true);
    sendRequest("/referral/getreferral", "POST", { candidate_id: id })
      .then((data) => {
        if (data.success) {
          // console.log(data.message)
          // setReferralId(data.message)
          sendRequest("/referral/referralStatistics", "POST", {
            referralCode: data.message,
          })
            .then((data) => {
              if (data.success) {
                // console.log(data)
                // eslint-disable-next-line array-callback-return
                data.message.map((referred) => {
                  const val = {
                    job_statistics: referred.job_statistics,
                    _id: referred._id,
                  };
                  setReferralList((referrals) => [...referrals, val]);
                });
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              // setLoading(false);
              // setError(true)
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, [id]);

  // referral/referralStatistics->setReferralList->/getuser->setName,Hired,shortlisted
  // Avatar from react-avatar
  // total Candidates,total shortlisted, total hired, total rejected

  if (error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={setError} />
        </div>
      </React.Fragment>
    );
  }

  if (isloading) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="Loading Referrals...." />
      </div>
    );
  }

  return (
    <div className="ma4-l ma4-m ma2">
      <p className="ma3 f3-l mb2 f4-m f6 pb1 gray tl">Referrals</p>
      <p className="ma3 mb2 f5-l f5-m f7-mo pb1 gray tl">
        Overview of candidates you have Invited
      </p>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justify="flex=start"
        spacing={1}
      >
        {referralList.length === 0 ? (
          <Grid item xs={12} md={9}>
            <div className="flex items-center br2 justify-center bg-white w-100 vh-25 br2">
              <p className="gray tc f5-l f5-m f6">No Referred Candidates.</p>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12} md={9}>
            <div className={` flex-1 ph2 mr1 w-100`}>
              {/* <Jobcards jobs={filtered?filterlist:joblist} setdesc={setdescription} form={form} setform={setform} salary={salary}/> */}
              <ReferralList list={referralList} />
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <div className="pb1">
            <ReferralURL id={id} />
          </div>
          {referralList.length === 0 ? (
            ""
          ) : (
            <div className="pb1">
              <ReferralStatistics list={referralList} />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ReferralDashboard;
