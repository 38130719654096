import React from "react";
import { useParams } from "react-router-dom";

const SuccessfullyApplied = () => {
  const params = useParams();
  const { jobName } = params;
  return (
    <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
      <div className="gray pa2 f3 flex flex-column items-center">
        <p>
          Successfully Applied to{" "}
          <span style={{ color: "#6eb6ff", textTransform: "capitalize" }}>
            {jobName}
          </span>
          !
        </p>
        <a
          href={"/dashboard"}
          name="jobs"
          style={{ background: "#6EB6FF" }}
          className={`c-shadow b tc h2 pointer h7-mo f8-mo f7-m f7-l mr2 bn link dim br2 ph3 pv2 dib white`}
        >
          {"Go to Dashboard to View Application Status"}
        </a>
      </div>
    </div>
  );
};

export default SuccessfullyApplied;
