import React from 'react'
import ReferralCard from './ReferralCard'

export default function ReferralList({list}) {

    return (
        <div id="Referrallist" className='jobs w-100 flex center flex-column ma-2'>
            {
                // jobs === undefined || jobs.length<=0 ?<div style={{height:"50vh"}} className='flex justify-center items-center'><p className='ma0 f6-l f6-m f7 gray tc'>{salary?'':'No jobs matched your skills yet, please be patient'}</p></div>:jobs.map((jobdata,id) =><JobCard job={jobdata} setdesc={setdesc} form={form} setform={setform} client={client} salary={salary} k={id} key={id}/>)
                
                list === undefined || list.length<=0?'':list.map((referraldata,id) =><ReferralCard refdata={referraldata} key={id}/>)
            }
        </div>
    )
}
