import React, { useState, useEffect } from "react";
import "tachyons";
import "../../styles/dashboard.scss";
import Jobcards from "../JobCards/Jobcardlist";
import { goToURL, sendRequest } from "../../util/helpers/helper-methods";
import { Snackbar, Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";
// import { jobs as getJobs } from '../../util/data/Jobs'
import Loading from "../Shared/Loading";
import ErrorPage from "../Shared/ErrorPageDashboard";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Filter from "./Filter";
import sanitizeHtml from "sanitize-html-react";

export default function AllJobs({ id }) {
  const [salary, setsalary] = useState(false);
  const [description, setdescription] = useState({
    desc: "",
    code: "",
    applied: true,
    id: "",
    title: "",
    type: "",
    link: "",
  });
  const [filters, setfilters] = useState([]);
  const [form, setform] = useState([]);
  const [read, setread] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [error, seterror] = useState(false);
  const [joblist, setjoblist] = useState([]);
  const [filterlist, setfilterlist] = useState([]);
  const [filtered, setfiltered] = useState(false);
  const [popup, setpopup] = useState(false);
  const [severity, setseverity] = useState("");
  const [message, setmessage] = useState("");
  const [apply, setapply] = useState(false);
  const [TotalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const jobsPerPage = 10;

  const changeCurrentPage = (event, value) => {
    try {
      setCurrentPage(value);
      if (filtered) {
        setfilterlist(filters[value - 1]);
      } else {
        joblist.splice(0, joblist.length);
        setisloading(true);
        sendRequest("/jobs/allclientjobs", "POST", { page: value - 1 })
          .then((job) => {
            if (job.success) {
              // eslint-disable-next-line array-callback-return
              job.data.map((job) => {
                const val = {
                  jobtype: "Direct Client",
                  id: job._id,
                  title: job.jobTitle,
                  location: {
                    city: job.city,
                    country: job.country,
                  },
                  description: job.description,
                  // salary:job.from+'-'+job.to+'€',
                  jobcode: job.jobCode,
                  applied: true,
                };
                setjoblist((joblist) => [...joblist, val]);
              });
              setisloading(false);
              setsalary(true);
            }
          })
          .catch((err) => {
            console.error(err);
            seterror(true);
          });
      }
      document.getElementById("jobslist").firstChild.click();
    } catch (err) {
      console.log(err);
      seterror(true);
    }
  };
  const handleClick = () => {
    sendRequest("/jobs/applyforjob", "POST", {
      candidate_id: id,
      jobId: description.id,
    })
      .then((data) => {
        if (data.success) {
          setdescription((prevdesc) => ({ ...prevdesc, applied: false }));
          setmessage(data.message);
          setseverity("success");
          setpopup(true);
        }
      })
      .catch((err) => {
        console.error(err);
        seterror(true);
      });
  };

  const applybutton = (c_id, j_id) => {
    sendRequest("/jobs/checkalreadyapplied", "POST", {
      candidate_id: c_id,
      jobId: j_id,
    })
      .then((data) => {
        if (!data.success) {
          setapply(true);
        } else {
          setapply(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (id === "") return;
    setisloading(true);
    sendRequest("/jobs/allclientjobs", "POST")
      .then((job) => {
        if (job.success) {
          // eslint-disable-next-line array-callback-return
          job.data.map((job) => {
            const val = {
              jobtype: "Direct Client",
              id: job._id,
              title: job.jobTitle,
              location: {
                city: job.city,
                country: job.country,
              },
              description: job.description,
              // salary:job.from+'-'+job.to+'€',
              jobcode: job.jobCode,
              applied: true,
            };
            setjoblist((joblist) => [...joblist, val]);
          });
          setTotalPages(Math.ceil(job.length / jobsPerPage));
          setsalary(true);
          setisloading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        seterror(true);
        setisloading(false);
      });
  }, [id]);

  if (error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={seterror} />
        </div>
      </React.Fragment>
    );
  }

  if (isloading) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="Loading Jobs..." />
      </div>
    );
  }

  // console.log(jobs[currentPage])
  // console.log(TotalPages);
  return (
    // <div className="ma4-l ma4-m ma">
    <div>
      <div
        onClick={() => setread(false)}
        className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
          read ? "act" : ""
        }`}
      ></div>
      {popup ? (
        <Snackbar
          open={popup}
          autoHideDuration={4000}
          onClose={() => {
            setpopup(false);
          }}
        >
          <Alert
            severity={severity}
            onClose={() => {
              setpopup(false);
            }}
            variant="filled"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <Filter
        setfilterjobs={setfilterlist}
        filterlist={filterlist}
        setfiltered={setfiltered}
        setdesc={setdescription}
        perPage={jobsPerPage}
        setfilters={setfilters}
        setTotal={setTotalPages}
        type="Direct Client"
        total={TotalPages}
        setloading={setisloading}
      />
      {joblist.length === 0 || (filterlist.length === 0 && filtered) ? (
        ""
      ) : (
        <div className="flex justify-center items-center">
          <Pagination
            count={TotalPages}
            size="small"
            showFirstButton
            shape="rounded"
            showLastButton
            defaultPage={1}
            page={currentPage}
            onChange={changeCurrentPage}
            boundaryCount={2}
          />
        </div>
      )}
      <div className="flex flex-row-l flex-column-m flex-column justify-around mt3">
        {joblist.length === 0 || (filterlist.length === 0 && filtered) ? (
          ""
        ) : (
          <div className={` flex-1 ph2 mr2 w-100`}>
            <Jobcards
              jobs={filtered ? filterlist : joblist}
              setdesc={setdescription}
              form={form}
              setform={setform}
              salary={salary}
            />
          </div>
        )}
        <div
          className={` flex-2 br2 bg-white pa4 ml1 mt0-l mt3-m mt3 w-100 flex justify-center items-start`}
        >
          {description.desc === "" ? (
            <p className="gray tc f5-l f5-m f6">No Jobs to show</p>
          ) : (
            <Grid id="#desc" container xs={12} spacing={3}>
              <Grid xs={12} className="mv1 flex justify-between">
                <Grid item xs={9}>
                  <p className="f4-l f5-m f6 gray">{description.title}</p>
                </Grid>
                {applybutton(id, description.id) ? (
                  ""
                ) : (
                  <Grid
                    item
                    xs={3}
                    className="flex justify-center items-center"
                  >
                    {description.type === "Direct Client" ? (
                      <button
                        onClick={handleClick}
                        style={{ background: "#6EB6FF" }}
                        className={`${
                          apply ? "c-shadow h2 pointer dim " : "success"
                        }  w6-l w5 h7-mo f8-mo f7-m f7-l mr2 bn br2 ph3 pv2 dib white flex-l justify-center items-center`}
                        disabled={!apply}
                      >
                        {apply ? "Apply" : `Applied  `}
                        {apply ? "" : <CheckCircleIcon className="pl2" />}
                      </button>
                    ) : (
                      <button
                        // onClick={()=>applied?setapplied(false):setapplied(true)}
                        onClick={() => goToURL(`${description.link}`)}
                        style={{ background: "#6EB6FF" }}
                        className="c-shadow h2 pointer dim w6-l w5 h7-mo f8-mo f7-m f7-l mr2 bn br2 ph3 pv2 dib white flex-l justify-center items-center"
                      >
                        Partner Link
                      </button>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={1}>
                {description?.desc && (
                  <div>
                    <p className="b gray f4-l f5">Job Description</p>
                    <div
                      className="gray f5-l f6"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(description?.desc),
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
      {joblist.length === 0 || (filterlist.length === 0 && filtered) ? (
        ""
      ) : (
        <div className="flex justify-start items-center mt2">
          <Pagination
            count={TotalPages}
            size="large"
            showFirstButton
            shape="rounded"
            showLastButton
            defaultPage={1}
            page={currentPage}
            onChange={changeCurrentPage}
            boundaryCount={2}
          />
        </div>
      )}
    </div>
  );
}
