import React from "react";
import // Grid,
// Typography,
// Box,
// Button,
// useMediaQuery
"@material-ui/core";
// import { goToURL } from '../../util/helpers/helper-methods';
import { Widget } from "react-typeform-embed";

const Feedback = ({ email }) => {
  // const screenAtSmall = useMediaQuery("(max-width:600px)");

  // const handleSubmitFeedback = () => {
  // 	goToURL("https://moyyn.com", false);
  // }

  return (
    <div className="vh-75">
      {/* <Grid container spacing={3}>
			<Grid item xs={12}> */}
      {/* <Typography
					color='textSecondary'
					style={{ marginBottom: "2rem" }}
					align="center"
				>
					Please take a moment to give us 
					feedback about your job matches
				</Typography> */}
      <Widget id="SE28gPfx" />
      {/* <Box m={2} display="flex" justifyContent="center">
					<Button
						className="moyynButton--main"
						onClick={handleSubmitFeedback}
						style={{ textTransform: 'none' }}
					>	
						{
							screenAtSmall ? 'Go Back' : "Go back to homepage"
						}
					</Button>
				</Box> */}
      {/* </Grid>
		</Grid> */}
    </div>
  );
};

export default Feedback;
