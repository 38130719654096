import React, { useState } from "react";
import { Button, Snackbar } from "@material-ui/core";
import { sendRequest } from "../../util/helpers/helper-methods";
import { Formik } from "formik";
import { AuthResetPass as validationSchema } from "../../util/validation/form-validation";
import { authresetforminitialvalue as initialValues } from "../../util/data/initial-values";
import FormError from "../ForgotPass/FormError";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";

export default function ResetPassword({ id, email }) {
  const [passwordupdate, setpasswordupdate] = useState(false);
  const [error, seterror] = useState({ val: false, msg: "" });
  const [succesfull, setsuccesfull] = useState(false);

  const HandleSubmit = (values) => {
    // else {
    sendRequest("/setnewpassword", "POST", {
      id: id,
      oldPassword: values.old_pass,
      newPassword: values.new_pass,
      timeStamp: new Date(),
    })
      .then((data) => {
        if (data.success) {
          seterror({ val: false, msg: "Password Changed!" });
          setsuccesfull(true);
          setTimeout(() => {
            setsuccesfull(false);
          }, 4000);
        } else {
          seterror({ val: true, msg: "Old password doesn't match!" });
        }
      })
      .catch(() => seterror({ val: true, msg: "Old password doesn't match!" }));
    // }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={HandleSubmit}
    >
      {(props) => {
        const {
          values, // eslint-disable-line
          handleChange, // eslint-disable-line
          handleBlur, // eslint-disable-line
          submitForm,
        } = props;
        return (
          <div>
            <div>
              <div className="creds mt3">
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email || ""}
                  disabled={true}
                  className={`w-50-l w-70-m w-80`}
                />
              </div>
              <div className="creds mt3">
                <TextField
                  name="old_pass"
                  label="Old Password"
                  variant="outlined"
                  type="password"
                  value={values.old_pass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-50-l w-70-m w-80`}
                />
                <div className="flex justify-center items-center">
                  <FormError name="old_pass" />
                </div>
              </div>
              <div className="creds mt3">
                <TextField
                  name="new_pass"
                  label="New Password"
                  type="password"
                  value={values.new_pass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  className={`w-50-l w-70-m w-80`}
                />
                <div className="flex justify-center items-center">
                  <FormError name="new_pass" />
                </div>
              </div>
              <div className="creds mt3">
                <TextField
                  name="confirm_pass"
                  label="Confirm Password"
                  type="password"
                  value={values.confirm_pass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  className={`w-50-l w-70-m w-80`}
                />
                <div className="flex justify-center items-center">
                  <FormError name="confirm_pass" />
                </div>
              </div>
              <div className="mt4">
                <Button
                  className="dim"
                  onClick={() => submitForm()}
                  variant="outlined"
                  style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
                >
                  Update Password
                </Button>
              </div>
            </div>
            <Snackbar
              open={passwordupdate || error.val || succesfull}
              autoHideDuration={5000}
              onClose={() => {
                setpasswordupdate(false);
                seterror({ val: false, msg: "" });
              }}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={() => {
                  setpasswordupdate(false);
                  seterror({ val: false, msg: "" });
                }}
                severity={error.val ? "error" : "success"}
              >
                {error.msg}
              </MuiAlert>
            </Snackbar>
          </div>
        );
      }}
    </Formik>
  );
}
