import React, { useState, useEffect } from "react";
import { sendRequest } from "../../util/helpers/helper-methods";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FileCopyIcon from "@material-ui/icons/FileCopy";

function ReferralCode({ id }) {
  const [url, seturl] = useState("");
  const [urlerror, seturlError] = useState("");
  const [error, setError] = useState(false);
  const [alert, setalert] = useState(false);

  const CopyAlert = () => {
    return (
      <Snackbar
        open={alert}
        autoHideDuration={4000}
        onClose={() => {
          setalert(false);
        }}
      >
        <Alert
          icon={<FileCopyIcon fontSize="inherit" />}
          severity="info"
          onClose={() => {
            setalert(false);
          }}
          variant="filled"
        >
          Copied to Clipboard
        </Alert>
      </Snackbar>
    );
  };

  const handleClick = () => {
    navigator.clipboard.writeText(url);
    setalert(true);
  };

  useEffect(() => {
    sendRequest("/referral/getreferral", "POST", { candidate_id: id })
      .then((data) => {
        if (data.success) {
          // console.log(url_string)
          const url_string =
            window.location.protocol +
            "//" +
            window.location.host +
            "/application?referral_id=" +
            data.message;
          seturl(url_string);
        }
      })
      .catch(() => {
        setError(true);
        seturlError("Error in generating url");
      });
  }, [id]);

  return (
    <div className={` flex-1`}>
      {alert ? <CopyAlert /> : ""}
      <div className="dn br2 db-l dn-m bg-white mh2 pa3">
        <p className="gray flex items-center justify-center f7-l f7-m f8-mo b">
          Referrals
        </p>
        <p className="gray f7-l f7-m f8-mo tj">
          Refer Moyyn to your friends and earn for every hire. Once they signup
          and get hired by one of our clients, you get a commission of 500€ for
          Pay Per Hire jobs and 50€ for a Basic job.
          <br />
          <br />
          <b>
            This is your unique referral link, share it with your network and
            earn now.
          </b>
        </p>
        <input
          id="referral-id"
          disabled
          placeholder={error ? urlerror : url}
          className="referral-link input-reset ba b--black-20 pa2 mb2 db w-100"
          type="text"
          value={error ? urlerror : url}
        />
        <p
          className="link flex justify-center items-center pa3 br2 f8-mo f7-m f7-l pointer dib h2 bn white dim"
          disabled={!!error}
          style={{ background: "#6EB6FF" }}
          onClick={handleClick}
        >
          Copy
        </p>
      </div>
    </div>
  );
}

export default ReferralCode;
