import React, { useState, useEffect } from "react";
import "tachyons";
import "../../styles/dashboard.scss";
import Jobcards from "../JobCards/Jobcardlist";
import { Snackbar, Grid, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { sendRequest } from "../../util/helpers/helper-methods";
import { Matched as getMatchedjobs } from "../../util/data/Jobs";
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Loading from "../Shared/Loading";
import ErrorPage from "../Shared/ErrorPageDashboard";
import Referral from "../ReferralElements/ReferralCode";
import sanitizeHtml from "sanitize-html-react";

export default function Client({ id }) {
  const [client, setclient] = useState(false);
  const [jobs, setjobs] = useState([]);
  const [description, setdescription] = useState({
    desc: "",
    code: "",
    id: "",
  });
  const [form, setform] = useState([]);
  // const [sendjobpref, setsendjobpref] = useState(false);
  // const [hov3, sethov3] = useState(false);
  const [read, setread] = useState(false);
  // const [directempty, setdirectempty] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [error, seterror] = useState(false);
  const [apply, setapply] = useState(false);
  const [withdrawn, setwithdrawn] = useState(false);
  const [popup, setpopup] = useState(false);
  const [severity, setseverity] = useState("");
  const [message, setmessage] = useState("");
  // const [sugg,setsugg]=useState({client:[]})

  const handleClick = () => {
    console.log(id, description.id);
    sendRequest("/jobs/applytopreferred", "POST", {
      id: id,
      jobId: description.id,
    })
      .then((data) => {
        // console.log(data)
        if (data.success) {
          setdescription((prevdesc) => ({ ...prevdesc, applied: false }));
          setmessage("Applied Successfully");
          setseverity("success");
          setpopup(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log("clicked");
  };

  const handleWithdraw = () => {
    sendRequest("/jobs/preferredtowithdraw", "POST", {
      id: id,
      jobId: description.id,
    })
      .then((data) => {
        if (data.success) {
          setdescription((prevdesc) => ({ ...prevdesc, applied: false }));
          setmessage("Withdrawn Successfully");
          setseverity("error");
          setpopup(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log("clicked");
  };

  const checkpreferred = (c_id, j_id) => {
    // console.log(c_id,j_id)
    sendRequest("/jobs/checkpreferred", "POST", {
      candidate_id: c_id,
      jobId: j_id,
    })
      .then((data) => {
        // console.log(data)
        if (!data.success) {
          setapply(true);
        } else {
          setapply(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log("checked");
  };

  const checkwithdrawn = (c_id, j_id) => {
    sendRequest("/jobs/checkwithdrawn", "POST", {
      candidate_id: c_id,
      jobId: j_id,
    })
      .then((data) => {
        if (!data.success) {
          setwithdrawn(true);
        } else {
          setwithdrawn(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log("checked");
  };

  useEffect(() => {
    if (id === "") return;
    setisloading(true);
    getMatchedjobs({ candidate_id: id })
      .then((result) => {
        // console.log(result)
        // eslint-disable-next-line array-callback-return
        result.map((job) => {
          sendRequest("/jobs/getjobwithjobid", "POST", { jobId: job })
            .then((data) => {
              // console.log(job,data)
              if (data.success) {
                const val = {
                  jobtype: "",
                  id: data.message._id,
                  title: data.message.jobTitle,
                  location: {
                    city: data.message.city,
                    country: data.message.country,
                  },
                  description: data.message.description,
                  // salary:data.message.from+'-'+data.message.to+'€',
                  jobcode: data.message.jobCode,
                };
                setjobs((jobs) => [...jobs, val]);
                setisloading(false);
              }
              if (result.length === 0 || result === null) {
                // setdirectempty(false);
                setclient(false);
                setdescription({ desc: "" });
              }
              // else{
              //     setclient(true)
              //     // setdirectempty(true);
              //     document.getElementById("jobslist").firstChild.click();
              // }
              setisloading(false);
            })
            .catch((err) => {
              console.log(err);
              setisloading(false);
              seterror(true);
            });
        });
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
        seterror(true);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (jobs.length === 0 || jobs === null) return;
    if (jobs.length !== 0) {
      setclient(true);
      // setdirectempty(true);
      if (!document.getElementById("jobslist")) {
        document.getElementById("jobslist").firstChild.click();
      }
    } else {
      // setdirectempty(false);
      setclient(false);
      setdescription({ desc: "" });
    }
  }, [jobs]);

  if (error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={seterror} />
        </div>
      </React.Fragment>
    );
  }

  if (isloading) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="Loading Jobs..." />
      </div>
    );
  }

  // console.log(jobs)
  return (
    <div>
      <div
        onClick={() => setread(false)}
        className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
          read ? "act" : ""
        }`}
      ></div>
      {popup ? (
        <Snackbar
          open={popup}
          autoHideDuration={4000}
          onClose={() => {
            setpopup(false);
          }}
        >
          <Alert
            severity={severity}
            onClose={() => {
              setpopup(false);
            }}
            variant="filled"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      {/* <div className={`br2 pop-up-box gray bg-white flex flex-column w-40-l w-50-m w-60 ma0 mr4 pa1 fixed ${read?'':'hide'}`}>
            <div onClick={()=>setread(false)} className='absolute black top-1 f4 dim right-1 pointer'>&times;</div>
            <Grid item xs={12} className="mt4">
                <Typography color='textSecondary' variant='h6' className="f5-l f5-m f7" align='center'>
                    {form.length<=0?"No company selected yet, please select your prefered companies":"Thank you. Your preferences have been submitted.You will be contacted by our clients directly if they select your application to the next round"}
                </Typography>
            </Grid>
            </div> */}
      {/* <div className={`flex justify-start items-center mt3 ml4 ${!client && directempty?'':'hide'}`}>
                <button onClick={() => setsendjobpref(true)} style={{ background: "#6EB6FF" }} className={`c-shadow h2 pointer h7-mo f8-mo f7-m f7-l mr2 w5 bn link dim br2 ph3 pv2 dib white`}>{screenAtTab?"":"Submit your"} Preferences</button>
                <div className="relative">
                    <div style={{background:"#eef2f5"}} className={`${hov3?'':'hide'} flex justify-center items-center c-shadow tc h3 w5 br2 absolute gray f7 top-1 z-11 right--1`}>Please select your preferred jobs from below and click 'Submit your preferences' button to save preferred jobs</div>
                    <div onMouseLeave={()=>sethov3(false)} onMouseOver={()=>sethov3(true)} style={{color:"#6EB6FF"}} className="pointer help-ico dim bottom--1 absolute"><HelpOutlineOutlinedIcon/></div>
                </div>
            </div> */}
      <div className="flex flex-row-l flex-column-m flex-column justify-around mt3">
        {jobs.length === 0 ? (
          ""
        ) : (
          <div className={` flex-1 ph2 mr1 w-100`}>
            <Jobcards
              jobs={jobs}
              setdesc={setdescription}
              form={form}
              setform={setform}
              client={client}
            />
          </div>
        )}
        <div
          className={` flex-2 br2 bg-white pa4 ml1 mt0-l mt3-m mt3 w-100 flex justify-center items-start`}
        >
          {description.desc === "" ? (
            <p className="gray tc f5-l f5-m f6">
              Please check out &apos;Client Jobs&apos; and start applying to
              your preferred jobs. Your job matching is in progress and you will
              see them within 24hrs.
            </p>
          ) : (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} className="mb1">
                <Typography color="textSecondary" variant="h6" align="center">
                  Job Description
                </Typography>
              </Grid>
              <Grid item xs={12} className="flex justify-center items-center">
                {checkpreferred(id, description.id) ? (
                  ""
                ) : (
                  <button
                    // onClick={()=>applied?setapplied(false):setapplied(true)}
                    onClick={handleClick}
                    style={{ background: "#6EB6FF" }}
                    className={`${
                      apply ? "c-shadow h2 pointer dim " : "success"
                    }  w4-l w3 h7-mo f8-mo f7-m f7-l mr2 bn br2 ph3 pv2 dib white flex-l justify-center items-center`}
                    disabled={!apply}
                  >
                    {apply ? "Apply" : `Applied  `}
                  </button>
                )}
                {/* {apply?'':<CheckCircleIcon className="pl2"/>} */}
                {checkwithdrawn(id, description.id) ? (
                  ""
                ) : (
                  <button
                    onClick={handleWithdraw}
                    style={{ background: "#6EB6FF" }}
                    className={`${
                      withdrawn ? "c-shadow h2 pointer dim " : "withdrawn"
                    }  w4-l w3 h7-mo f8-mo f7-m f7-l mr2 bn br2 ph3 pv2 dib white flex-l justify-center items-center`}
                    disabled={!withdrawn}
                  >
                    {withdrawn ? "Withdraw" : "Withdrawn"}
                  </button>
                )}
              </Grid>
              <Grid container spacing={1}>
                {description?.desc && (
                  <div>
                    <p className="b gray f4-l f5">Job Description</p>
                    <div
                      className="gray f5-l f6"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(description?.desc),
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </div>
        <Referral id={id} />
      </div>
    </div>
  );
}
