import React from "react";
import { Typography, Button } from "@material-ui/core";

const ErrorPage = ({
  setError,
  logged = false,
  nextLink = "https://moyyn.com/maintenance/",
}) => {
  const handleClick = () => {
    setError(false);
    window.location.href = nextLink;
  };

  if (logged) {
    return (
      <React.Fragment>
        <Typography align="center" className="w-80" color="textSecondary">
          There has been an issue while loading your application. Please click
          &apos;Next&apos; to go back to dashboard.
          <br />
          <br />
          <Button
            onClick={setError}
            variant="outlined"
            style={{
              backgroundColor: "#6EB6FF",
              color: "white",
              paddingBottom: "2px",
            }}
            className="c-shadow dim w-20"
          >
            <Typography>Next</Typography>
          </Button>{" "}
        </Typography>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Typography align="center" className="w-80" color="textSecondary">
        Thank you for your interest in applying to our Talent Pool. There has
        been an issue while submitting your application. Please click
        &apos;Next&apos; to get a new application form.
        <br />
        <br />
        <Button
          onClick={handleClick}
          variant="outlined"
          style={{
            backgroundColor: "#6EB6FF",
            color: "white",
            paddingBottom: "2px",
          }}
          className="c-shadow dim w-20"
        >
          <Typography>Next</Typography>
        </Button>{" "}
      </Typography>
      {/* <div className="flex justify-end items-end">
					<a className="fixed bottom-1 right-1" href="/feedback" target="_blank" rel="noopener noreferrer">
					<Button
						className='moyynBtn mt4'
					>
						Give us a Feedback
					</Button>
					</a>
			</div> */}
    </React.Fragment>
  );
};

export default ErrorPage;
