import {
  careerLevelOptions,
  industries,
  countriesArray,
  citiesArray,
} from "../../util/data/static-data";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import SelectMenu from "../FormElements/SelectMenuForm";
import WorkExperience from "../FormElements/WorkExperience";
import Languages from "../FormElements/Languages";
import SkillSuggestion from "../FormElements/SkillSuggestion";
import AutocompleteChips from "../FormElements/AutocompleteChipsForm";
import Buttons from "../FormElements/Buttons";
import MissingParts from "../FormElements/MissingParts";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { pageTwoValidation as validationSchema } from "../../util/validation/form-validation";
import ResumeUpload from "../FormElements/ResumeUpload";
import { url } from "../../util/data/base-url";
import ResumeUploadGer from "../FormElements/ResumeUploadGer";
import { AvailableJobs as initialjobs } from "../../util/data/AvailableJobs";
import ReferralTextField from "../ReferralElements/ReferralTextField";
import { sendRequest } from "../../util/helpers/helper-methods";
import Autocomplete from "../FormElements/AutocompleteForm";
import { HelpOutline } from "@material-ui/icons";

const PageTwo = ({
  initialValues,
  handleFormChange,
  editform,
  formComplete,
  referral,
  query,
}) => {
  const [AvailableJobs, setAvailableJobs] = useState([]);
  useEffect(() => {
    fetch(`${url}/desiredjoblist`, {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAvailableJobs(data.data.reverse());
        }
      })
      .catch(() => setAvailableJobs(initialjobs));
  }, []);
  const [alert, setAlert] = useState([]);

  const handleSubmit = (values, { setErrors }) => {
    if (
      !values.referralcode ||
      values.referralcode === "" ||
      values.referralcode === "null"
    ) {
      handleFormChange(values, 1, false, true, true);
      const [isComplete, missingParts] = formComplete();
      if (!isComplete) {
        setAlert(missingParts);
      }
    } else {
      sendRequest("/referral/checkreferralId", "POST", {
        referralcode: values.referralcode,
      }).then((data) => {
        if (data.success) {
          handleFormChange(values, 1, false, true, true);
          const [isComplete, missingParts] = formComplete();
          if (!isComplete) {
            setAlert(missingParts);
          }
        } else setErrors({ referralcode: "Invalid Referral Code" });
      });
    }
  };
  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  useEffect(() => {
    if (query) {
      const data = initialValues;
      data.referralcode = referral;
    }
  }, [initialValues, query, referral]);

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <div className="bg-white c-shadow">
              <Form onKeyDown={onKeyDown}>
                <Grid container spacing={3} xs={12} style={{ padding: "2rem" }}>
                  <Grid item xs={12} container spacing={3}>
                    <Grid item xs={6}>
                      <ResumeUpload editform={editform} />
                    </Grid>
                    <Grid item xs={6}>
                      <ResumeUploadGer editform={editform} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="lsv">
                    <AutocompleteChips
                      name="desiredPositions"
                      placeholder="Desired Positions"
                      options={AvailableJobs}
                    />
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid spacing={2} container>
                      <Grid item xs={6} className="lsv">
                        <Autocomplete
                          freeSolo={false}
                          options={countriesArray}
                          optionLabel="name"
                          name="country"
                          placeholder="Country of Residence"
                        />
                      </Grid>
                      <Grid item xs={6} className="lsv">
                        <Autocomplete
                          options={citiesArray}
                          optionLabel="city"
                          name="city"
                          placeholder="City of Residence"
                        />{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <WorkExperience />
                  </Grid>
                  <Grid item xs={12} className="lsv">
                    <AutocompleteChips
                      name="industries"
                      placeholder="Industries"
                      options={industries}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SkillSuggestion
                      name="skills"
                      jobCodesList={values?.desiredPositions.map((job) => {
                        const regex = /\(\s(?<code>.*)\s\)/g;
                        return regex.exec(job)[1];
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectMenu
                      name="careerLevel"
                      label="Career level"
                      options={careerLevelOptions}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={6}>
                      <Typography
                        style={{ marginBottom: 12, display: "flex", gap: 4 }}
                        color="textSecondary"
                      >
                        Languages{" "}
                        <Tooltip title="A1, A2 -> Beginner level |  B1,B2 -> Intermediate level | C1,C2 -> Fluent">
                          <Typography color="textSecondary">
                            <HelpOutline />{" "}
                          </Typography>
                        </Tooltip>
                      </Typography>
                    </Grid>

                    <Languages />
                  </Grid>
                  <Grid item xs={12}>
                    <ReferralTextField
                      disabled={!!query}
                      name="referralcode"
                      referral={referral}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MissingParts alert={alert} />
                  </Grid>
                  <Grid item xs={12}>
                    <Buttons
                      editform={editform}
                      back={() => handleFormChange(values, 3, true, false)}
                      submit
                    />
                  </Grid>
                </Grid>
              </Form>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default PageTwo;
