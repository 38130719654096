import React, { useState } from "react";
import { Button, Snackbar } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import { sendRequest } from "../../util/helpers/helper-methods";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { ResetPass as validationSchema } from "../../util/validation/form-validation";
import { resetforminitialvalue as initialValues } from "../../util/data/initial-values";
import FormError from "./FormError";

export default function UpdatePass({ email, setEmail, setid, setloggedin }) {
  const [passwordupdate, setpasswordupdate] = useState(false);
  const [openemail, setopenemail] = useState(true);
  const [error, seterror] = useState(false);

  const history = useHistory();

  const handleSubmit = (values) => {
    sendRequest("/resetpassword", "POST", {
      email: email,
      dummypassword: values.otp,
      newpassword: values.password,
    })
      .then((data) => {
        if (data.success) {
          setpasswordupdate(true);
          sendRequest("/login", "POST", {
            email: email,
            password: values.password,
          }).then((data) => {
            if (data.success) {
              if (!data.resetpassFlag) {
                setEmail(data.email);
                setid(data.candidate_id);
                setloggedin(true);
                setpasswordupdate(false);
                localStorage.setItem("loggedin", JSON.stringify(true));
                localStorage.setItem("email", JSON.stringify(email));
                localStorage.setItem("id", JSON.stringify(data.candidate_id));
                history.push("/dashboard");
              }
            } else {
              history.push("/candidate/partners");
            }
          });
        } else {
          seterror(true);
        }
      })
      .catch(() => seterror(true));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const {
          values, // eslint-disable-line
          handleChange, // eslint-disable-line
          handleBlur, // eslint-disable-line
          submitForm,
        } = props;

        return (
          <div>
            <div className="mv4 pa3 w-95 center flex-1 tc bg-white">
              <p className="gray tc f3">Reset Password</p>
              <div>
                <div className="creds mt3">
                  <TextField
                    name="otp"
                    label="One Time Password"
                    variant="outlined"
                    type="password"
                    value={values.otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-80 w-60-l w-70-m"
                  />
                  <div className="flex justify-center items-center">
                    <FormError name="otp" />
                  </div>
                </div>
                <div className="creds mt3">
                  <TextField
                    name="password"
                    label="New Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    className="w-80 w-60-l w-70-m"
                  />
                  <div className="flex justify-center items-center">
                    <FormError name="password" />
                  </div>
                </div>
                <div className="creds mt3">
                  <TextField
                    name="confirmpassword"
                    label="Confirm Password"
                    type="password"
                    value={values.confirmpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    className="w-80 w-60-l w-70-m"
                  />
                  <div className="flex justify-center items-center">
                    <FormError name="confirmpassword" />
                  </div>
                </div>
                <div className="center w-30 mt3">
                  <Snackbar
                    open={openemail}
                    autoHideDuration={5000}
                    onClose={() => {
                      setopenemail(false);
                    }}
                  >
                    <MuiAlert
                      elevation={4}
                      variant="filled"
                      onClose={() => {
                        setopenemail(false);
                      }}
                      severity={"success"}
                    >
                      Please check your Email for One Time Password
                    </MuiAlert>
                  </Snackbar>
                </div>
                <div className="mt4">
                  <Button
                    className="dim"
                    onClick={() => submitForm()}
                    variant="outlined"
                    style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
                  >
                    Update Password
                  </Button>
                </div>
              </div>
              <Snackbar
                open={passwordupdate || error}
                autoHideDuration={5000}
                onClose={() => {
                  seterror(false);
                  setpasswordupdate(false);
                }}
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={() => {
                    seterror(false);
                    setpasswordupdate(false);
                  }}
                  severity={error ? "error" : "success"}
                >
                  {`${
                    error
                      ? "Incorrect One Time Password!"
                      : "Password Succesfully Updated!"
                  }`}
                </MuiAlert>
              </Snackbar>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
