import { FormatColorResetOutlined, LocationOn } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./check.css";

function Jobcard({ job, setdesc, form, setform, client, k, salary }) {
  const [show, setshow] = useState(FormatColorResetOutlined);
  const [check, setcheck] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [hover, sethover] = useState(false);

  useEffect(() => {
    if (show) {
      if (job.description === undefined) {
        // setdesc("No Job Description!")
        setshow(false);
      } else {
        if (salary) {
          setdesc({
            desc: job.description,
            code: job.jobcode,
            applied: job.applied,
            title: job.title,
            id: job.id,
            type: job.jobtype,
            link: job.link,
          });
        } else {
          setdesc({
            desc: job.description,
            code: job.jobcode,
            id: job.job_id,
          });
        }
        setshow(false);
      }
    }
  }, [job, show, setdesc, salary]);

  useEffect(() => {
    if (clicked) {
      if (check) {
        localStorage.setItem(k, JSON.stringify(true));
      } else {
        localStorage.setItem(k, JSON.stringify(false));
      }
      setclicked(false);
    }
    setcheck(JSON.parse(localStorage.getItem(k)));
    // console.log(form)
  }, [check, k, clicked]);

  // const handleaccept = () =>{
  //     if (form.includes(job.job_id)) {
  //     setform((prev) => prev.filter((c) => c !== job.job_id));
  //     } else {
  //         setform((prev) => [...prev, job.job_id]);
  //     }
  // }

  return (
    <div
      onClick={() => {
        const access = document.getElementById("#desc");
        access.scrollIntoView({ behavior: "smooth" }, true);
        setshow(true);
      }}
      className="card pointer c-shadow ma1 bg-white pa3-l pa3-m pa2 mt3 br3 flex justify-center items-center"
    >
      <Grid container direction="column" alignItems="flex-start" spacing={1}>
        {job.jobtype === "" ||
        job.jobtype === null ||
        job.jobtype === undefined ? (
          ""
        ) : (
          <Grid item>
            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                {job.jobtype === "Direct Client" ||
                job.jobtype === "Partner Job" ? (
                  <div className="relative">
                    <div
                      style={{ background: "#eef2f5" }}
                      className={`${
                        hover ? "" : "hide"
                      } flex justify-center pa1 items-center c-shadow tc h3 w5 br2 absolute gray f7 top-1 z-11 `}
                    >
                      {job.jobtype === "Direct Client"
                        ? "This job is posted by our direct client and the applicants are managed directly on the platform"
                        : "This jobs is an external offer, managed by some of our partner platforms. If you are interested in applying, please select the Recommendation link and apply externally"}
                    </div>
                    <p
                      className="pointer dim ma0 gray f8 f9-m f9-mo pa1 ba br2"
                      onMouseLeave={() => sethover(false)}
                      onMouseOver={() => sethover(true)}
                      style={{ color: "#6EB6FF" }}
                    >
                      {job.jobtype}
                    </p>
                  </div>
                ) : (
                  <p
                    className="ma0 gray f8 f9-m f9-mo pa1 ba br2"
                    style={{ color: "#6EB6FF" }}
                  >
                    {job.jobtype}
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <p className="ma0 f6-l f6-m mt1 f8-mo">
            {client ? job.title + " " + job.jobcode : job.title}
          </p>
        </Grid>
        <Grid item>
          <p
            style={{ color: "#6EB6FF" }}
            className="ma0 gray f8 mt1 f8-m f9-mo flex items-center"
          >
            <LocationOn fontSize="small" />
            {job.location.city + ", " + job.location.country}
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Jobcard;

// <div onClick={() => {window.scrollTo({top: 3, behavior: 'smooth'});setshow(true)}} className="card pointer c-shadow ma1 bg-white pa3-l pa3-m pa2 mt3 br3 flex justify-center items-center">
//             <div className={`${client?'flex justify-around items-center':''} w-100`}>
//                 {/* {job.jobtype==='client'?<p className='ma0 gray f8 f9-m f9-mo pa1 ba br2' style={{color:"#6EB6FF"}}>Direct Client</p>:''} */}
//                 <div className='flex-1 flex w-80-l w-80-m w-90 flex-column'>
//                     <p className='ma0 f6-l f6-m mt1 f8-mo'>{job.title}</p>
//                     {/* {salary?<p className='ma0 gray f8 f9-m f9-mo'>{job.salary}</p>:''} */}
//                     <p style={{ color: "#6EB6FF" }} className='ma0 gray f8 mt1 f8-m f9-mo'>{job.location.city + ", " + job.location.country}</p>
//                 </div>
//                 {/* {
//                     salary?'':
//                     <div className={`${client?'hide':''}`}>
//                         <div className="flex-1">
//                             <div className="cbx">
//                                 <input onChange={()=>{setcheck(!check); setclicked(true); handleaccept()}} checked={check} id="cbx" type="checkbox" />
//                                 <label htmlFor="cbx"></label>
//                                 <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
//                                     <path d="M2 8.36364L6.23077 12L13 2"></path>
//                                 </svg>
//                             </div>
//                             <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" version="1.1">
//                                 <defs>
//                                     <filter id="goo">
//                                         <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
//                                         <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo"></feColorMatrix>
//                                         <feBlend in="SourceGraphic" in2="goo"></feBlend>
//                                     </filter>
//                                 </defs>
//                             </svg>
//                         </div>
//                     </div>
//                 } */}
//             </div>
//         </div>
