import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Candidate from "./pages/Candidate";
import Form from "./pages/Form";
import "./App.css";
import Header from "./components/Header/header";
import DashboardHeader from "./components/Header/DashboardHeader";
import Footer from "./components/Footer/footer";
// import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";
import ReferralDashboard from "./pages/ReferralDashboard";
import Settings from "./pages/Settings";
import EditProfile from "./pages/EditProfile";
import ForgotPass from "./components/ForgotPass/EnterEmail";
import Feedback from "./pages/Feedback";
import { sendRequest } from "./util/helpers/helper-methods";
import { ApplyForJob } from "./pages/ApplytoJob";
import SuccessfullyApplied from "./components/FormPages/SuccessfullyApplied";

const App = () => {
  const [loggedin, setloggedin] = useState(
    JSON.parse(localStorage.getItem("loggedin")) !== undefined
      ? JSON.parse(localStorage.getItem("loggedin"))
      : false,
  );
  const [email, setEmail] = useState(JSON.parse(localStorage.getItem("email")));
  const [id, setid] = useState(JSON.parse(localStorage.getItem("id")));
  const [referralId, setReferralId] = useState("");
  const [query, setquery] = useState(false);

  useEffect(() => {
    sendRequest("/getcandidatestatus", "POST", {
      id: "60c9bd1f4a67ceb9057788b4",
    }).then((data) => {
      if (data.success) {
        // console.log(data);
        if (data.message === false)
          window.location = "https://moyyn.com/scheduled-maintenance";
      }
    });
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const referral_id = url.searchParams.get("referral_id");
    if (referral_id === null || referral_id === undefined) {
      setquery(false);
    } else {
      sendRequest("/referral/checkreferralId", "POST", {
        referralcode: referral_id,
      }).then((data) => {
        if (data.success) {
          setReferralId(referral_id);
          // console.log(referral_id)
          setquery(true);
        } else {
          alert("Please Check Your Referral Code");
          window.location.href = "/application";
        }
      });
    }
    // console.log(referral_id)
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/application" />
          </Route>
          <Route exact path="/apply/:jobName/:jobId/:candidateId">
            <Header />
            <ApplyForJob id={id} />
            <Footer />
          </Route>
          <Route exact path="/apply/:jobName">
            <Form
              setEmail={setEmail}
              referral={referralId}
              openApply={true}
              query={query}
              setid={setid}
              setloggedin={setloggedin}
            />
          </Route>
          <Route exact path="/applied/:jobName">
            <Header />
            <SuccessfullyApplied />
            <Footer />
          </Route>
          <Route path="/application">
            {loggedin ? (
              <Redirect to="/dashboard" />
            ) : (
              <>
                <Header />
                <Form
                  setEmail={setEmail}
                  referral={referralId}
                  query={query}
                  setid={setid}
                  setloggedin={setloggedin}
                />
              </>
            )}
          </Route>
          <Route path="/forgotpass">
            {loggedin ? (
              <Redirect to="/dashboard" />
            ) : (
              <ForgotPass
                setloggedin={setloggedin}
                email={email}
                setEmail={setEmail}
                setid={setid}
              />
            )}
          </Route>
          <Route path="/candidate">
            {loggedin ? (
              <Redirect to="/dashboard" />
            ) : (
              <>
                <Header />
                <Candidate
                  email={email}
                  setEmail={setEmail}
                  id={id}
                  setid={setid}
                  setloggedin={setloggedin}
                />
                <Footer />
              </>
            )}
          </Route>
          {loggedin && (
            <>
              <Route path="/dashboard">
                <DashboardHeader setloggedin={setloggedin} />
                <Dashboard id={id} email={email} />
              </Route>
              <Route path="/referrals">
                <DashboardHeader setloggedin={setloggedin} />
                <ReferralDashboard id={id} email={email} />
              </Route>
              <Route path="/editprofile">
                <DashboardHeader setloggedin={setloggedin} />
                <EditProfile
                  id={id}
                  email={email}
                  setEmail={setEmail}
                  setid={setid}
                />
              </Route>
              <Route path="/feedback">
                <Header />
                <Feedback />
              </Route>
              <Route path="/settings">
                <DashboardHeader setloggedin={setloggedin} />
                <Settings
                  id={id}
                  email={email}
                  setloggedin={setloggedin}
                  loggedin={loggedin}
                />
              </Route>
            </>
          )}
          <Redirect to="/application" />
        </Switch>
      </Router>

      {/* <Divider/> */}
    </React.Fragment>
  );
};

export default App;
