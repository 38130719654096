/* eslint-disable dot-notation */
import React, { useEffect, useState } from "react";
import Form from "./Form";
import ErrorPage from "../components/Shared/ErrorPageDashboard";
import Loading from "../components/Shared/Loading";
import { useHistory } from "react-router-dom";
import { sendRequest } from "../util/helpers/helper-methods";

export default function EditProfile({ setEmail, id, setid }) {
  const history = useHistory();

  const [initialdata, setinitialdata] = useState([]);
  const [error, seterror] = useState(false);
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    if (id === "") return;
    setisloading(true);
    sendRequest("/getuser", "POST", {
      candidate_id: id,
    })
      .then((data) => {
        if (data.length > 0) {
          const test = data;
          test[1].cvEnglish = test[1].cvEnglish
            ? [{ data: `https://spaces.moyyn.com/englishCVs/${test[1].cv}` }]
            : [];
          test[1].cvGerman = test[1].cvGerman
            ? [{ data: `https://spaces.moyyn.com/germanCVs/${test[1].cv}` }]
            : [];
          if (test[1].desiredPositions === null) test[1].desiredPositions = [];
          if (test[3].countryPreferences === null)
            test[3].countryPreferences = [];
          if (test[3].cityPreferences === null) test[3].cityPreferences = [];
          if (test[3].desiredEmployment === null) {
            test[3].desiredEmployment = {
              remote: false,
              partTime: false,
              fulltime: false,
              freelance: false,
            };
          }
          if (test[3].onlineProfiles === null) {
            test[3].onlineProfiles = {
              Stackoverflow: "",
              LinkedIn: "",
              Github: "",
              Xing: "",
              Dribbble: "",
              Behance: "",
              Other: "",
            };
          }
          if (test[4].industries === null) test[4].industries = [];
          if (test[4].skills === null) test[4].skills = [];
          if (test[4].workExperience === null) test[4].workExperience = [];
          if (test[4].languages === null) test[4].languages = [];
          test[1]["industries"] = test[4].industries;
          test[1]["skills"] = test[4].skills;
          test[1]["workExperience"] = test[4].workExperience;
          test[1]["languages"] = test[4].languages;
          test[1]["careerLevel"] = test[4].careerLevel;
          test[1]["country"] = test[2].country;
          test[1]["city"] = test[2].city;

          console.log(test);
          setinitialdata(test);
          seterror(false);
          setisloading(false);
        } else {
          setisloading(false);
          seterror(true);
        }
      })
      .catch(() => {
        setisloading(false);
        seterror(true);
      });
  }, [id]);

  const handleError = () => {
    history.push("/dashboard");
    seterror(false);
  };

  if (error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage logged={true} setError={handleError} />
        </div>
      </React.Fragment>
    );
  }

  if (isloading) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="Loadind form data.." />
      </div>
    );
  }

  return (
    <>
      {initialdata.length > 0 && (
        <Form
          setEmail={setEmail}
          setid={setid}
          editform={true}
          initialdata={initialdata}
        />
      )}
    </>
  );
}
