import React, { useEffect, useState } from 'react';
import {Avatar, Grid} from '@material-ui/core';
import {sendRequest} from '../../util/helpers/helper-methods';

export default function ReferralCard({refdata}) {

    const [name,setName]=useState('');
    const [initials,setInitials]=useState('');
    const [hired,setHired]=useState(0);
    const [rejected,setRejected]=useState(0);
    const [shortlisted,setShortlisted]=useState(0);
    const [preferred,setPreffered]=useState(0);
    const [registered,setRegistered]=useState('');

    useEffect(()=>{
    //   console.log(refdata)
      const stats=refdata.job_statistics;
      setHired(stats.hired.length);
      setRejected(stats.rejected.length);
      setShortlisted(stats.shortlisted.length);
      setPreffered(stats.combined_applied_preferred.length)
      sendRequest('/users/getcandidatedetails','POST',{candidate_id:refdata._id})
      .then((data)=>{
        // console.log(data.message[0])
        setName(data.message[0].firstName+' '+data.message[0].lastName)
        setInitials(data.message[0].firstName[0]+data.message[0].lastName[0])
        const c_date=new Date(data.message[0].createdAt);
        setRegistered(c_date.getDate()+'-'+(c_date.getMonth()+1)+'-'+c_date.getFullYear())
        // setRegistered(c_date);

      })
      .catch((err)=>{
        console.log(err)
      })
    },[refdata])

    return (
        <div className="card c-shadow bg-white pa3-l pa3-m pa2 br3 flex justify-center items-center ma1 mt3">
            <Grid container direction="column" alignItems="flex-start" spacing={1}>
                {/* <Grid item>
                    <p  style={{ color: "#6EB6FF" }} className='ma0 gray f8 f8-m f9-mo'>{careerLevel}</p>
                </Grid> */}
                <Grid item>    
                    <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                            <Avatar style={{background:"#6EB6FF",height:60,width:60,fontSize:30}} >{initials}</Avatar>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-start">
                                <Grid item>
                                        <p className='ma0 pl2 gray f8 f8-m f9-mo'>{`Registered on: ${registered}`}</p>
                                </Grid>
                                <Grid item>
                                    <p className='ma0 f5-l gray f5-m mt1 f7-mo pl2'>{name}</p>
                                </Grid>
                                <Grid item>
                                    <p className='br2 dib bg-light-gray ma1 gray f7 f7-m f8-mo pa2'>{`Applied Jobs: ${preferred}`}</p>
                                    <p className='br2 dib bg-light-gray ma1 gray f7 f7-m f8-mo pa2'>{`Shortlisted: ${shortlisted}`}</p>
                                    <p className='ma1 gray f7 f7-m f8-mo pa2 br2 dib bg-light-gray'>{`Rejected: ${rejected}`}</p>
                                    <p className='ma1 gray f7 f7-m f8-mo pa2 br2 dib bg-light-gray'>{`Hired: ${hired}`}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <p className="link pointer dib br2 bg-black white ma1 pa2 f7 f7-m f8-mo">Details</p> */}
        </div>
        // <p>Referral data</p>
    )
}