import React, { useState, useEffect, memo } from "react";
import { FieldArray } from "formik";
import { Chip, TextField, Grid, List } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./SkillSuggestion.scss";
import PDLJS from "peopledatalabs";
import { clientMomatchUrl } from "../../util/data/base-url";
import { isEqual } from "lodash";
import FormError from "./FormError";

function SkillSuggestion({ name, jobCodesList }) {
  const [title, setTitle] = useState([]);
  const [skills, setSkills] = useState([]);
  const [skillInput, setSkillInput] = useState("");

  const PDLJSClient = new PDLJS({
    apiKey: process.env.REACT_APP_PEOPLE_DATA_LABS,
  });

  const getSkillsFromJobCode = async () => {
    const data = await fetch(`${clientMomatchUrl}/jobcode/skills`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        jobCodesList,
      }),
    });
    const jobCodeData = await data.json();
    if (jobCodeData.success) {
      setTitle([...new Set(jobCodeData.skills)]);
    }
  };

  useEffect(() => {
    if (!jobCodesList && jobCodesList.length <= 0) return;
    getSkillsFromJobCode();
  }, [jobCodesList]);

  useEffect(() => {
    if (!skillInput) return;
    const timer = setTimeout(() => {
      PDLJSClient.autocomplete({
        field: "skill",
        text: skillInput,
        size: 10,
      })
        .then((res) => {
          setSkills(res.data.map((skill) => skill.name));
        })
        .catch((err) => console.log(err));
    }, 1000);
    return () => clearTimeout(timer);
  }, [skillInput]);

  return (
    <FieldArray name={name}>
      {({ form, push, remove }) => {
        const handleChange = (event, values) => {
          event.stopPropagation();
          if (event.keyCode === 8) {
            remove(values.length);
          } else {
            push(values[values.length - 1]);
          }
        };

        const handleClick = (event, values, idx) => {
          event.stopPropagation();
          const list = [...title];
          if (event.keyCode === 8) {
            remove(values.length);
          } else {
            push(values);
            list.splice(idx, 1);
            setTitle(list);
          }
        };

        const onInputChange = (event, value) => {
          try {
            setSkillInput(value);
          } catch (e) {
            console.log("error in skills2 input");
          }
        };

        return (
          <React.Fragment>
            <Grid container direction="column" spacing={1}>
              <Grid item className="lsv">
                <Autocomplete
                  multiple
                  options={skills}
                  onChange={handleChange}
                  onInputChange={onInputChange}
                  freeSolo
                  value={form.values[name]}
                  closeIcon={false}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option}
                        key={option}
                        onDelete={() => remove(index)}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      variant="outlined"
                      placeholder="Skills"
                    />
                  )}
                />
                <FormError name={name} />
              </Grid>
              <Grid item className={title.length === 0 ? "dn" : "lsv"}>
                <div>
                  <p className="gray">Skills Suggestions</p>
                  <List>
                    {title.map((item, idx) => (
                      <Chip
                        key={idx}
                        label={item}
                        className="suggestion"
                        clickable
                        style={{
                          border: "1px solid #6EB6FF",
                          background: "white",
                          color: "#6EB6FF",
                        }}
                        onClick={(event) => handleClick(event, item, idx)}
                      />
                    ))}
                  </List>
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      }}
    </FieldArray>
  );
}

export default memo(SkillSuggestion, (prevProps, nextProps) => {
  return isEqual(prevProps.jobCodesList, nextProps.jobCodesList);
});
