import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { categoriesAndRoles, citiesArray } from "../../util/data/static-data";
import { filtervalue } from "../../util/data/initial-values";
// import { sendRequest } from '../../util/helpers/helper-methods';
import { clientfilter, partnerfilter } from "../../util/data/Jobs";
import ErrorPage from "../Shared/ErrorPageDashboard";
import "./filter.scss";
import { sendRequest } from "../../util/helpers/helper-methods";

export default function Filter({
  setfilterjobs,
  setfiltered,
  filterlist,
  setdesc,
  perPage,
  setfilters,
  setTotal,
  type,
  total,
  setloading,
}) {
  const [inputs, setinputs] = useState(filtervalue);
  const [error, seterror] = useState(false);
  const categories = Object.keys(categoriesAndRoles);
  const citiesList = [];
  const getFilter = type === "Direct Client" ? clientfilter : partnerfilter;
  const getTotalLength =
    type === "Direct Client" ? "/jobs/allclientjobs" : "/jobs/getpartnerjobs";
  // const countryList=[]

  const handleChange = (event) => {
    event.persist();
    setinputs((inputs) => ({ ...inputs, jobTitle: event.target.value }));
  };

  const handleCategoryChange = (event) => {
    event.persist();
    if (event.target.value === "" || event.target.value === null)
      setinputs((inputs) => ({ ...inputs, category: event.target.value }));
    else setinputs((inputs) => ({ ...inputs, category: event.target.value }));
  };

  const handleCityChange = (event, value) => {
    event.persist();
    if (value === null || value === undefined) {
      setinputs((inputs) => ({ ...inputs, city: "" }));
    } else setinputs((inputs) => ({ ...inputs, city: value }));
  };

  const ApplyFilter = () => {
    setloading(true);
    setfiltered(true);
    // console.log(inputs)
    filterlist.splice(0, filterlist.length);
    getFilter(inputs, perPage)
      .then((result) => {
        // console.log(result)
        if (result.length === 0) {
          setdesc({ desc: "" });
        } else {
          setfilters(result);
          const val = result[0];
          setfilterjobs(val);
          setTotal(result.length);
          document.getElementById("jobslist").firstChild.click();
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        seterror(true);
      });
  };

  const ResetFilter = () => {
    setloading(true);
    setfiltered(false);
    filterlist.splice(0, filterlist.length);
    sendRequest(getTotalLength, "POST").then((result) => {
      if (type === "Direct Client") {
        setTotal(Math.ceil(result.length / perPage));
      } else {
        setTotal(Math.ceil(result.len / perPage));
      }
      setloading(false);
    });
  };

  // useEffect(() => {
  //     countriesArray.map((data)=>(
  //     countryList.push(data.name)
  //     ));
  //     // console.log(countryList)
  //     },[countryList])

  useEffect(() => {
    citiesArray.map((data) => citiesList.push(data.name));
    // console.log(citiesList)
  }, [citiesList]);

  if (error) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={seterror} />
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="flex w-70-l w-40-m w-100 items-center br2 pa1 mv3 ma0-l ma0-m ">
        <Grid
          className="input-reset"
          container
          direction="row"
          alignItems="center"
          spacing={1}
        >
          {/* <Grid item xs>
                        <p>Filter Jobs.</p>
                    </Grid> */}
          <Grid item xs>
            <TextField
              select
              className="bg-white br2"
              size="small"
              variant="outlined"
              fullWidth
              label="Category"
              value={inputs.category}
              onChange={handleCategoryChange}
              SelectProps={{
                native: true,
                style: { fontSize: 12 },
              }}
              InputLabelProps={{ style: { fontSize: 10 } }}
            >
              <option value=""> </option>
              {categories.map((categoryName) => (
                <option key={categoryName} value={categoryName}>
                  {categoryName}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs>
            <TextField
              className="bg-white br2 jobTitle"
              // select={title?true:false}
              // select
              size="small"
              name="jobTitle"
              variant="outlined"
              fullWidth
              label="Job Title"
              onChange={handleChange}
              value={inputs.jobTitle}
              SelectProps={{
                native: true,
              }}
              inputProps={{ style: { fontSize: 12 } }}
              InputLabelProps={{ style: { fontSize: 10 } }}
            />
            {/* <option value=""> </option>
                            {title?   
                                RolesList.map((categoryName) => (
                                    <option key={categoryName} value={categoryName}>
                                        {categoryName}
                                    </option>
                                ))
                                :''}
                            </TextField> */}
          </Grid>
          <Grid item xs>
            <Autocomplete
              options={citiesList}
              // freesolo="true"
              onChange={(e, v) => handleCityChange(e, v)}
              className="bg-white br2 city"
              value={inputs.city}
              size="small"
              // inputProps={{style:{fontSize:12}}}
              ListboxProps={{
                style: { fontSize: 12 },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="city"
                  InputLabelProps={{ style: { fontSize: 10 } }}
                  label="City"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {/* <Grid item xs>
                        <Grid container direction="column" spacing={1} alignItems="flex-start">
                            <Grid item xs>
                                <button onClick={ApplyFilter}>Apply Filter</button> 
                            </Grid>
                            <Grid item xs>
                                <button onClick={ResetFilter}>Reset</button>
                            </Grid>
                        </Grid>
                    </Grid> */}
          <Grid item xs>
            <p
              style={{ background: "#6EB6FF" }}
              className="link flex justify-center items-center pa3 br2 f8-mo f7-m f7-l pointer dib h2 bn white dim"
              onClick={ApplyFilter}
            >
              Search
            </p>
          </Grid>
          <Grid item xs>
            <p
              className="link flex justify-center items-center pa3 br2 f8-mo f7-m f7-l pointer dib h2 bn white dim"
              style={{ background: "#6EB6FF" }}
              onClick={ResetFilter}
            >
              Reset
            </p>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
