import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Link,
  FormHelperText,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { sendRequest } from "../../util/helpers/helper-methods";
import Header from "../../components/Header/header";
import ResetPass from "./UpdatePass";

export default function EnterEmail({ setloggedin, setid, setEmail, email }) {
  const [name, setName] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);
  const [resetpassflag, setresetpassflag] = useState(false);

  const screenAtSmall = useMediaQuery("(max-width:600px)");
  const history = useHistory();

  const handleEmailChange = (event) => {
    if (isNotFound) {
      setIsNotFound(false);
    }
    setEmail(event.target.value.toLowerCase());
  };

  const handleRequest = () => {
    sendRequest("/application", "POST", { email })
      .then((data) => {
        if (!data.success) {
          setName(data.name);
        } else {
          setIsNotFound(true);
        }
      })
      .catch(() => {});
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRequest();
    }
  };

  const handleNameApprove = () => {
    sendRequest("/forgotpassword", "POST", { email: email }).then((data) => {
      if (data.success) {
        setresetpassflag(true);
      }
    });
  };

  return (
    <>
      <Header />
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <React.Fragment>
          <Grid container spacing={7}>
            {!name && !resetpassflag && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography color="textSecondary" align="center">
                    Password Recovery
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box
                          width="80%"
                          display="flex"
                          marginBottom="2rem"
                          justifyContent="center"
                        >
                          <TextField
                            value={email}
                            onChange={handleEmailChange}
                            onKeyDown={handleKeyDown}
                            variant="outlined"
                            label="Enter email address"
                            inputProps={{
                              style: { width: "30rem", paddingBottom: "2rem" },
                            }}
                          />
                        </Box>
                      </Box>
                      <div className="flex justify-center items-center mt4">
                        <div className="w-50 flex justify-center items-center">
                          <Button
                            className="moyynButton--main mb4"
                            onClick={handleRequest}
                          >
                            Request New Password
                          </Button>
                        </div>
                      </div>
                    </Grid>

                    {isNotFound && (
                      <Grid item xs={12}>
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="center"
                        >
                          <FormHelperText style={{ color: "red" }}>
                            No Such account found!
                          </FormHelperText>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    align="center"
                  >
                    Go to login? Click{" "}
                    <Link
                      onClick={() =>
                        history.push("/candidate/check-application")
                      }
                      style={{ cursor: "pointer", color: "#6EB6FF" }}
                    >
                      {" "}
                      here
                    </Link>
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
            {name && !resetpassflag && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Are you {name}?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box width="100%" display="flex" justifyContent="center">
                    <Button
                      onClick={() => setName("")}
                      style={{ marginRight: "1rem", textTransform: "none" }}
                      className="moyynButton--secondary"
                    >
                      {screenAtSmall ? "No" : "No, let me try again"}
                    </Button>
                    <Button
                      onClick={() => handleNameApprove()}
                      style={{ marginLeft: "1rem", textTransform: "none" }}
                      className="moyynButton--main"
                    >
                      {screenAtSmall ? "Yes" : "Yes, let's continue"}
                    </Button>
                  </Box>
                </Grid>
              </React.Fragment>
            )}
            {resetpassflag && (
              <div className="w-100">
                <ResetPass
                  setloggedin={setloggedin}
                  setid={setid}
                  setEmail={setEmail}
                  email={email}
                />
              </div>
            )}
          </Grid>
        </React.Fragment>
      </div>
    </>
  );
}
