import React, { useState } from "react";
import "tachyons";
import { useMediaQuery } from "@material-ui/core";
import Shortlisted from "../components/DashboardElements/Shortlisted";
import Rejected from "../components/DashboardElements/Rejected";
import Hired from "../components/DashboardElements/Hired";
import Client from "../components/DashboardElements/Client";
import AllClientJobs from "../components/DashboardElements/AllClientJobs";
import Applied from "../components/DashboardElements/AppliedJobs";
import Withdrawn from "../components/DashboardElements/Withdrawn";

export default function Dashboard({ id }) {
  const [active, setActive] = useState({
    jobs: true,
    status: false,
  });
  const [statusActive, setstatusActive] = useState({
    applied: true,
    shortlisted: false,
    rejected: false,
    hired: false,
    withdraw: false,
  });

  const [jobActive, setjobActive] = useState({
    matched: false,
    clientJobs: true,
    partnerJobs: false,
  });
  const [matchedHover, setMatchedHover] = useState(false);
  const [clientHover, setClientHover] = useState(false);
  // const [partnerHover, setPartnerHover] = useState(false);
  const [appliedHover, setAppliedHover] = useState(false);
  const [shortlistedHover, setShortlistedHover] = useState(false);

  // const screenAtSmall = useMediaQuery("(max-width:600px)");
  const screenAtTab = useMediaQuery("(max-width:1024px)");

  const handleActive = (e) => {
    e.persist();
    if (e.target.name === "jobs") {
      setActive({
        jobs: true,
        status: false,
      });
    }
    if (e.target.name === "status") {
      setActive({
        status: true,
        jobs: false,
      });
    }
  };

  const handlejobActive = (e) => {
    e.persist();
    if (e.target.id === "matched") {
      setjobActive({
        matched: true,
        clientJobs: false,
        partnerJobs: false,
      });
    }
    if (e.target.id === "clientjobs") {
      setjobActive({
        matched: false,
        clientJobs: true,
        partnerJobs: false,
      });
    }
    if (e.target.id === "partnerjobs") {
      setjobActive({
        matched: false,
        clientJobs: false,
        partnerJobs: true,
      });
    }
  };

  const handlestatusActive = (e) => {
    e.persist();
    if (e.target.id === "applied") {
      setstatusActive({
        applied: true,
        shortlisted: false,
        rejected: false,
        hired: false,
        withdraw: false,
      });
    }
    if (e.target.id === "shortlisted") {
      setstatusActive({
        applied: false,
        shortlisted: true,
        rejected: false,
        hired: false,
        withdraw: false,
      });
    }
    if (e.target.id === "rejected") {
      setstatusActive({
        applied: false,
        shortlisted: false,
        rejected: true,
        hired: false,
        withdraw: false,
      });
    }
    if (e.target.id === "hired") {
      setstatusActive({
        shortlisted: false,
        rejected: false,
        hired: true,
        applied: false,
        withdraw: false,
      });
    }
    if (e.target.id === "withdraw") {
      setstatusActive({
        shortlisted: false,
        rejected: false,
        hired: false,
        applied: false,
        withdraw: true,
      });
    }
  };

  return (
    <div className="ma4-l ma4-m ma2">
      <p className="ma2 f3-l mb2 f4-m f6 pb2 gray tl">
        Check out your job matches.
      </p>
      <div className="flex flex-column justify-around items-start">
        <div className="flex justify-around flex-row-l flex-column">
          <div className="bg-white c-shadow flex flex2 w-100 br2 pa3 mb2 flex flex-column items-center">
            <p className="tc gray">
              Explore jobs from more than 2000 companies Check out our partner
              platform and expand your job search. With your personal
              recommendation link, you can get fast-tracked in the application
              process for the jobs you apply via our partner platform
            </p>
            <a
              href="https://moyyn.com/de-personal-recommendation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                name="jobs"
                style={{ background: "#6EB6FF" }}
                className={`c-shadow h2 pointer h7-mo f8-mo f7-m f7-l mr2 w6-l w5-m w5 bn link dim br2 ph3 pv2 dib white`}
              >
                Recommendation link
              </button>
            </a>
          </div>
          <div className="w3"></div>
          <div className="bg-white c-shadow flex w-40-l w-100 br2 pa3 mb2 flex flex-column items-center">
            <p className="tc gray">
              Checkout Moyyn&apos;s Interview Handbook, to prepare for your
              interviews
            </p>
            <a
              href="https://moyyn.com/moyyn-interview-handbook/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                name="jobs"
                style={{ background: "#6EB6FF" }}
                className={`c-shadow h2 pointer h7-mo f8-mo f7-m f7-l mr2 w6-l w5-m w5 bn link dim br2 ph3 pv2 dib white`}
              >
                Interview Handbook
              </button>
            </a>
          </div>
        </div>
        <div className="flex ma3 ma0-l ma0-m w-30-l w-40-m w-60 justify-around br2 pa2 ph1">
          <button
            onClick={handleActive}
            name="jobs"
            style={{ background: "#6EB6FF" }}
            className={` ${
              active.jobs ? "" : "active"
            } c-shadow h2 pointer h7-mo f8-mo f7-m f7-l mr2 w6-l w5-m w5 bn link dim br2 ph3 pv2 dib white`}
          >
            Jobs
          </button>
          <button
            onClick={handleActive}
            name="status"
            style={{ background: "#6EB6FF" }}
            className={` ${
              active.status ? "" : "active"
            } c-shadow h2 pointer h7-mo f8-mo f7-m f7-l mr2 w6-l w5-m w5 bn link dim br2 ph3 pv2 dib white flex-1-l`}
          >
            Status
          </button>
        </div>
      </div>
      {active.jobs ? (
        <div>
          <div className="flex ma3 ma0-l ma0-m w-70-l w-40-m w-60 justify-start br2 pa2">
            <div className="relative">
              <div
                className={`${
                  clientHover ? "" : "hide"
                } flex justify-center pa1 items-center c-shadow tc h3 w5 br2 absolute white bg-dark-gray f7 top-4 z-11 `}
              >
                These are &apos;ALL&apos; jobs posted by our direct clients.
              </div>
              <p
                onClick={handlejobActive}
                onMouseLeave={() => setClientHover(false)}
                onMouseOver={() => setClientHover(true)}
                id="clientjobs"
                className={`${
                  jobActive.clientJobs
                    ? "b bb bw1 b--light-blue light-blue"
                    : ""
                } h2 link flex items-center pa3 pointer dib f8-mo f7-m f7-l gray`}
              >
                All Jobs
              </p>
            </div>
            <div className="relative">
              <div
                className={`${
                  matchedHover ? "" : "hide"
                } flex justify-center pa1 items-center c-shadow tc h3 w5 br2 absolute white bg-dark-gray f7 top-4 z-11`}
              >
                These are jobs posted by our clients, for which your profile got
                matched. You can Apply or Withdraw from these anytime.
              </div>
              <p
                onClick={handlejobActive}
                onMouseLeave={() => setMatchedHover(false)}
                onMouseOver={() => setMatchedHover(true)}
                id="matched"
                className={`${
                  jobActive.matched ? "b bb bw1 b--light-blue light-blue" : ""
                } h2 link flex items-center pa3 pointer dib f8-mo f7-m f7-l gray`}
              >
                Matched
              </p>
            </div>
          </div>
          {jobActive.matched ? (
            <Client id={id} />
          ) : jobActive.clientJobs ? (
            <AllClientJobs id={id} />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          <div className="flex ma3 ma0-l ma0-m w-70-l w-40-m w-60 justify-start br2 pa2">
            <div className="relative">
              <div
                className={`${
                  appliedHover ? "" : "hide"
                } flex justify-center pa1 items-center c-shadow tc h3 w5 br2 absolute white bg-dark-gray f7 top-4 z-11 `}
              >
                Your applications for the jobs from Matched and &apos;All
                Jobs&apos; section appear here
              </div>
              <p
                onClick={handlestatusActive}
                onMouseLeave={() => setAppliedHover(false)}
                onMouseOver={() => setAppliedHover(true)}
                id="applied"
                className={`${
                  statusActive.applied
                    ? "b bb bw1 b--light-blue light-blue"
                    : ""
                } h2 link flex items-center pa3 pointer dib f8-mo f7-m f7-l gray`}
              >
                Applied {screenAtTab ? "" : "Jobs"}
              </p>
            </div>
            <div className="relative">
              <div
                className={`${
                  shortlistedHover ? "" : "hide"
                } flex justify-center pa1 items-center c-shadow tc h3 w5 br2 absolute white bg-dark-gray f7 top-4 z-11 `}
              >
                If a client shortlists you for an applied job, it appears here.
              </div>
              <p
                onClick={handlestatusActive}
                onMouseLeave={() => setShortlistedHover(false)}
                onMouseOver={() => setShortlistedHover(true)}
                id="shortlisted"
                className={`${
                  statusActive.shortlisted
                    ? "b bb bw1 b--light-blue light-blue"
                    : ""
                } h2 link flex items-center pa3 pointer dib f8-mo f7-m f7-l gray`}
              >
                Shortlisted
              </p>
            </div>
            <p
              onClick={handlestatusActive}
              id="rejected"
              className={`${
                statusActive.rejected ? "b bb bw1 b--light-blue light-blue" : ""
              } h2 link flex items-center pa3 pointer dib f8-mo f7-m f7-l gray`}
            >
              Rejected
            </p>
            <p
              onClick={handlestatusActive}
              id="hired"
              className={`${
                statusActive.hired ? "b bb bw1 b--light-blue light-blue" : ""
              } h2 link flex items-center pa3 pointer dib f8-mo f7-m f7-l gray`}
            >
              Interviews
            </p>
            <p
              onClick={handlestatusActive}
              id="withdraw"
              className={`${
                statusActive.withdraw ? "b bb bw1 b--light-blue light-blue" : ""
              } h2 link flex items-center pa3 pointer dib f8-mo f7-m f7-l gray`}
            >
              Withdrawn
            </p>
          </div>
          {statusActive.applied ? (
            <Applied id={id} />
          ) : statusActive.shortlisted ? (
            <Shortlisted id={id} />
          ) : statusActive.rejected ? (
            <Rejected id={id} />
          ) : statusActive.hired ? (
            <Hired id={id} />
          ) : statusActive.withdraw ? (
            <Withdrawn id={id} />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
