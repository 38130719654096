import React from "react";
import { Field } from "formik";
import { TextField } from "@material-ui/core";
import FormError from "../FormElements/FormError";

const ReferralTextField = ({ name, referral, disabled }) => {
  return (
    <React.Fragment>
      <Field name={name}>
        {({ field, meta }) => {
          return (
            <TextField
              {...field}
              className="referral-field"
              placeholder={disabled ? referral : "Referral Id (Optional)"}
              fullWidth
              disabled={disabled}
              variant="outlined"
            />
          );
        }}
      </Field>
      <FormError name={name} />
    </React.Fragment>
  );
};

export default ReferralTextField;
