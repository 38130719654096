import React,{useState,useEffect} from 'react'
import {Grid,Typography} from '@material-ui/core';

export default function ReferralStatistics({list}) {

    const [hired,setHired]=useState(0);
    const [shortlisted,setShortlisted]=useState(0);
    const [total,setTotal]=useState(0);
    // total candidates
    // total hired,rejected,shortlisted, preferred

    useEffect(()=>{
        setTotal(list.length)
        // eslint-disable-next-line array-callback-return
        list.map((data)=>{
        const stats=data.job_statistics;
        setHired((prevData)=>prevData+stats.hired.length)
        setShortlisted((prevData)=>prevData+stats.shortlisted.length)
        })
    },[list])

    return (
        <div className="flex-1">
            <div className="dn br2 db-l dn-m bg-white mh2 pa2">
                <p className="flex gray b items-center dib justify-center f7-l f7-m f8-mo b">Candidate Statistics</p>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <Typography variant="h4" className="gray">{total}</Typography>
                        <p className="f8 f8-m f9-mo">Invited</p>
                    </Grid>
                    <Grid item xs style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <Typography variant="h4" className="gray">{shortlisted}</Typography>
                        <p className="f8 f8-m f9-mo">Shortlisted</p>
                    </Grid>
                    <Grid item xs style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <Typography variant="h4" className="gray">{hired}</Typography>
                        <p className="f8 f8-m f9-mo">Hired</p>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

