import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sendRequest } from "../util/helpers/helper-methods";
import ErrorPage from "../components/Shared/ErrorPage";
import Loading from "../components/Shared/Loading";
import sanitizeHtml from "sanitize-html-react";

export const ApplyForJob = ({ id }) => {
  const params = useParams();
  const { jobName, jobId, candidateId } = params;
  const [isApplied, setIsApplied] = useState(false);
  const [isError, setIsError] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!jobId) return;
    sendRequest("/jobs/getjobwithjobid", "POST", { jobId: jobId })
      .then((data) => {
        if (data.success) {
          setJobData(data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
      });
    setIsLoading(false);
  }, []);

  const applyToJob = () => {
    if (!candidateId && !jobId) return;
    setIsLoading(true);
    sendRequest("/jobs/applyforjob", "POST", {
      candidate_id: candidateId,
      jobId: jobId,
    })
      .then((data) => {
        if (data.success && data.message !== "No such user with this user id") {
          setIsApplied(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
      });
    setIsLoading(false);
  };

  if (isError) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
          <ErrorPage setError={setIsError} logged={false} nextLink="/" />
        </div>
      </React.Fragment>
    );
  }

  if (isApplied) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2">
        <div className="gray pa2 f3 flex flex-column items-center">
          <p>
            Successfully Applied to{" "}
            <span style={{ color: "#6eb6ff", textTransform: "capitalize" }}>
              {jobName}
            </span>
            !
          </p>
          <a
            href={id ? "/dashboard" : "/candidate/check-application"}
            name="jobs"
            style={{ background: "#6EB6FF" }}
            className={`c-shadow b tc h2 pointer h7-mo f8-mo f7-m f7-l mr2 bn link dim br2 ph3 pv2 dib white`}
          >
            {id
              ? "Go to Dashboard to View Application Status"
              : "Login to View Application Status"}
          </a>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <Loading text="Loading Application!" />;
  }

  return (
    <div className="flex flex-column justify-center items-center ma4 pa2">
      <div style={{ maxWidth: "800px" }}>
        <div className="flex justify-between items-center">
          <h2 className="f3-l f4-m f5">{jobData?.jobTitle}</h2>
          <button
            style={{ background: "#6EB6FF" }}
            className={`c-shadow b tc h2 pointer w-20 h7-mo f8-mo f7-m f7-l mr2 bn link dim br2 ph3 pv2 dib white`}
            onClick={() => applyToJob()}
          >
            Apply
          </button>
        </div>
        {jobData?.description && (
          <div>
            <p className="b gray f4-l f5">Job Description</p>
            <div
              className="gray f5-l f6"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(jobData?.description),
              }}
            />
          </div>
        )}
        {jobData?.requirements && (
          <div>
            <p className="b gray f4-l f5">Job Requirements</p>
            <div
              className="gray f5-l f6"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(jobData?.requirements),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
