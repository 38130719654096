import * as React from "react";

function FormImage2(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={240}
      height={200}
      viewBox="0 0 777.697 613.285"
      {...props}
    >
      <path
        d="M141.794 72.334a10.056 10.056 0 00-8.543 12.837l-29.074 20.776 17.33 6.67 24.953-20.753a10.11 10.11 0 00-4.666-19.53z"
        fill="#ffb8b8"
      />
      <path
        d="M139.09 103.629l-36.064 36.287a29.896 29.896 0 01-18.42 8.63l-44.563 4.065a12.28 12.28 0 01-9.115-2.864 11.996 11.996 0 015.293-20.95l45.527-9.695 41.448-30.553a3.793 3.793 0 013.77.028l11.271 9.234a3.703 3.703 0 011.868 3.887 3.618 3.618 0 01-1.015 1.93z"
        fill="#2f2e41"
      />
      <path
        d="M571.476 123.13a12.132 12.132 0 00-6.717-12.2L340.317 1.235a12.1 12.1 0 00-16.186 5.551l-.004.008-84.18 172.234a12.121 12.121 0 005.56 16.19l224.441 109.697a12.121 12.121 0 0016.19-5.56l84.18-172.233a12.012 12.012 0 001.158-3.991z"
        fill="#f2f2f2"
      />
      <path
        d="M439.99 505.403H148.766a13.845 13.845 0 01-13.83-13.829V96.224a13.845 13.845 0 0113.83-13.829H439.99a13.845 13.845 0 0113.83 13.83v395.35a13.845 13.845 0 01-13.83 13.828z"
        fill="#fff"
      />
      <circle cx={226.008} cy={181.285} r={19} fill="#e6e6e6" />
      <path
        d="M197.05 233.214a54.431 54.431 0 008.399 3.87l.513.179a54.767 54.767 0 0018.206 3.092c1.248 0 2.45-.043 3.572-.128a54.135 54.135 0 008.123-1.12 54.672 54.672 0 004.349-1.14c.342-.1.66-.2 1.006-.309a55.183 55.183 0 0016.052-8.318 24.805 24.805 0 00-1.279-2.149 27.425 27.425 0 00-22.983-12.406h-10a27.516 27.516 0 00-25.025 16.088 24.984 24.984 0 00-.933 2.341z"
        fill="#e6e6e6"
      />
      <path
        d="M232.456 238.633a3.618 3.618 0 00-1.78.625 53.17 53.17 0 007.97-1.107z"
        fill="#2f2e41"
      />
      <path
        d="M227.16 129.725a55.566 55.566 0 00-27.72 103.72 54.639 54.639 0 008.83 4.11l.52.18a55.258 55.258 0 0018.37 3.12c1.21 0 2.42-.04 3.61-.13a54.65 54.65 0 008.19-1.13c1.51-.33 2.99-.72 4.39-1.15.34-.1.66-.2 1.01-.31a55.571 55.571 0 00-17.2-108.41zm32.24 97.19a52.681 52.681 0 01-15.95 8.41c-.31.1-.63.2-.95.3-1.33.41-2.73.77-4.17 1.08a51.23 51.23 0 01-7.75 1.08c-1.13.08-2.28.12-3.42.12a52.544 52.544 0 01-17.39-2.95l-.49-.17a51.236 51.236 0 01-8.76-4.12 52.576 52.576 0 1158.88-3.75z"
        fill="#ccc"
      />
      <path
        d="M439.99 505.403H148.766a13.845 13.845 0 01-13.83-13.829V96.224a13.845 13.845 0 0113.83-13.829H439.99a13.845 13.845 0 0113.83 13.83v395.35a13.845 13.845 0 01-13.83 13.828zM148.766 84.023a12.216 12.216 0 00-12.202 12.202v395.35a12.216 12.216 0 0012.202 12.201H439.99a12.216 12.216 0 0012.202-12.202V96.224a12.216 12.216 0 00-12.202-12.202z"
        fill="#ccc"
      />
      <path
        d="M383.613 185.076h-82.407a6.52 6.52 0 110-13.039h82.407a6.52 6.52 0 110 13.039zM410.505 207.078H301.206a6.52 6.52 0 110-13.038h109.299a6.52 6.52 0 010 13.038zM383.493 291.467H178.25a6.52 6.52 0 110-13.038h205.242a6.52 6.52 0 110 13.038zM410.385 313.47H178.25a6.52 6.52 0 110-13.039h232.134a6.52 6.52 0 010 13.04zM383.493 364.04H178.25a6.52 6.52 0 110-13.038h205.242a6.52 6.52 0 110 13.038zM410.385 386.043H178.25a6.52 6.52 0 010-13.038h232.134a6.52 6.52 0 110 13.038zM383.493 436.614H178.25a6.52 6.52 0 010-13.039h205.242a6.52 6.52 0 110 13.039zM410.385 458.616H178.25a6.52 6.52 0 010-13.038h232.134a6.52 6.52 0 010 13.038z"
        fill="#e6e6e6"
      />
      <path
        d="M561.476 236.13a12.132 12.132 0 00-6.717-12.2L330.317 114.235a12.1 12.1 0 00-16.186 5.551l-.004.008-84.18 172.234a12.121 12.121 0 005.56 16.19l224.441 109.697a12.121 12.121 0 0016.19-5.56l84.18-172.233a12.012 12.012 0 001.158-3.991z"
        fill="#fff"
      />
      <path
        d="M561.476 236.13a12.132 12.132 0 00-6.717-12.2L330.317 114.235a12.1 12.1 0 00-16.186 5.551l-.004.008-84.18 172.234a12.121 12.121 0 005.56 16.19l224.441 109.697a12.121 12.121 0 0016.19-5.56l84.18-172.233a12.012 12.012 0 001.158-3.991zM474.86 411.729a10.693 10.693 0 01-14.285 4.906L236.132 306.937a10.693 10.693 0 01-4.906-14.285l84.18-172.233a10.693 10.693 0 0114.286-4.906L554.133 225.21a10.693 10.693 0 014.906 14.285z"
        fill="#3f3d56"
      />
      <path
        d="M536.996 243.961l-58.78 120.266a3.369 3.369 0 01-4.506 1.549L269.55 265.99a2.839 2.839 0 01-.417-.246 3.367 3.367 0 01-1.13-4.261l58.78-120.266a3.369 3.369 0 014.505-1.549l204.16 99.785a3.369 3.369 0 011.55 4.505z"
        fill="#e6e6e6"
      />
      <circle cx={357.759} cy={208.287} r={29.567} fill="#6EB6FF" />
      <path
        d="M401.05 330.86l-130.313-63.691a2.954 2.954 0 01-.468-.277l113.81-76.633a4.803 4.803 0 017.511 3.672l6.348 91.857.306 4.397z"
        fill="#3f3d56"
      />
      <path
        opacity={0.2}
        style={{
          isolation: "isolate",
        }}
        d="M401.05 330.86l-44.492-21.746 37.347-21.05 2.689-1.516 1.344-.759.306 4.396 2.806 40.675z"
      />
      <path
        d="M473.607 366.323l-112.71-55.088 37.347-21.05 2.689-1.517 48.668-27.433c3.19-1.798 7.76.227 8.798 3.667a5.718 5.718 0 01.16.71z"
        fill="#3f3d56"
      />
      <path
        fill="#ffb8b8"
        d="M125.844 376.82l12.259-.001 5.833-47.288-18.095.001.003 47.288z"
      />
      <path
        d="M122.716 372.817h24.145a15.386 15.386 0 0115.387 15.386v.5l-39.53.001z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M30.551 369.014l12.702 2.484 15.628-47.81-18.746-3.667-9.584 48.993z"
      />
      <path
        d="M28.123 364.233l25.014 4.893h.001a16.243 16.243 0 0112.822 19.06l-.101.518-40.956-8.011z"
        fill="#2f2e41"
      />
      <path
        d="M143.68 213.699a10.056 10.056 0 00-14.136-6.16l-25.554-24.978-3.526 18.232 24.807 20.925a10.11 10.11 0 0018.409-8.02z"
        fill="#ffb8b8"
      />
      <circle cx={48.871} cy={60.566} r={24.561} fill="#ffb8b8" />
      <path
        d="M43.713 85.503c1.305.57 3.978-9.827 2.78-11.907-1.78-3.093-1.674-3.07-2.856-5.117s-1.446-4.847.084-6.648 5.072-1.561 5.77.696c-.449-4.288 3.792-7.735 7.993-8.703s8.633-.367 12.857-1.23c4.903-1 10.003-5.11 8.047-10.5a7.593 7.593 0 00-1.48-2.434c-2.26-2.541-5.422-3.626-8.513-4.675-6.43-2.183-13.036-4.392-19.822-4.152a28.798 28.798 0 00-25.446 18.61 26.156 26.156 0 00-1.084 4.025C19.714 65.992 26.987 78.346 38.8 83.115zM37.683 220.427a3.754 3.754 0 01-1.778-3.717l-11.083-54.901-11.234-42.135A21.074 21.074 0 0124.14 95.583l7.427-7.027 16.203 6.98 7.699 7.828a44.027 44.027 0 0122.993 27.261l12.348 42.255 28.518 42.427a3.773 3.773 0 01-.37 4.68c-3.967 4.267-81.274.44-81.274.44z"
        fill="#2f2e41"
      />
      <path
        d="M112.396 216.526l-42.05-29.14a29.896 29.896 0 01-11.727-16.62l-11.82-43.159a12.28 12.28 0 011.221-9.476 11.996 11.996 0 0121.554 1.536l17.53 43.12 37.35 35.446a3.793 3.793 0 01.633 3.716l-7.113 12.717a3.703 3.703 0 01-3.498 2.52 3.618 3.618 0 01-2.08-.66z"
        fill="#2f2e41"
      />
      <path
        d="M25.036 361.695a3.744 3.744 0 01-.447-3.423l19.454-70.72 1.438-69.294 65.486-4.491.318-.022 19.724 35.75a91.977 91.977 0 0111.1 36.234l6.5 71.23a3.716 3.716 0 01-3.25 4.064l-19.746 1.878a3.674 3.674 0 01-4.079-3.111l-8.018-72.383-28.85-48.745-9.684 51.318-20.063 70.87a3.719 3.719 0 01-3.458 2.425l-23.4.01a3.64 3.64 0 01-3.025-1.59z"
        fill="#2f2e41"
      />
      <path
        d="M526.465 594.285H33a33.037 33.037 0 01-33-33v-271a33.037 33.037 0 0133-33h60.503a32.925 32.925 0 0122.195 8.58l65.611 46.85a23.182 23.182 0 0015.659 6.07h332.56a33 33 0 0132.997 33.483l-3.064 209.5a33.138 33.138 0 01-32.996 32.517z"
        fill="#fff"
      />
      <path
        d="M522.08 578.285H35.92A15.938 15.938 0 0120 562.365v-275.43a15.938 15.938 0 0115.92-15.92A14.096 14.096 0 0150 285.095v258.27a14.936 14.936 0 0014.92 14.92h455.519a10.125 10.125 0 019.915 7.587 9.72 9.72 0 01-4.307 11.185 7.595 7.595 0 01-3.966 1.228z"
        fill="#f2f2f2"
      />
      <path
        d="M283.424 446.013l-1.28-1.28a1.37 1.37 0 01-.063-1.937q.03-.033.063-.063l11.23-11.24a1.37 1.37 0 011.937-.064q.033.03.063.064l11.23 11.23a1.39 1.39 0 01.035 1.965l-.035.035-1.28 1.28a1.39 1.39 0 01-1.965.034l-.035-.034-6.64-7v16.61a1.38 1.38 0 01-1.37 1.39h-1.86a1.39 1.39 0 01-1.39-1.39v-16.59l-6.64 7a1.39 1.39 0 01-1.965.024z"
        fill="#6EB6FF"
      />
      <path
        d="M294.354 421.283a22.79 22.79 0 11-22.79 22.79 22.81 22.81 0 0122.79-22.79m0-3a25.79 25.79 0 1025.79 25.79 25.79 25.79 0 00-25.79-25.79z"
        fill="#3f3d56"
      />
      <path
        d="M526.465 594.285H33a33.037 33.037 0 01-33-33v-271a33.037 33.037 0 0133-33h60.503a32.925 32.925 0 0122.195 8.58l65.611 46.85a23.182 23.182 0 0015.659 6.07h332.56a33 33 0 0132.997 33.483l-3.064 209.5a33.138 33.138 0 01-32.996 32.517zM33 259.285a31.035 31.035 0 00-31 31v271a31.035 31.035 0 0031 31h493.465a31.13 31.13 0 0030.996-30.547l3.064-209.5a31 31 0 00-30.996-31.453H196.968a25.18 25.18 0 01-16.962-6.551l-65.611-46.851a30.931 30.931 0 00-20.892-8.098z"
        fill="#3f3d56"
      />
      <path
        fill="#ffb8b8"
        d="M568.285 599.561h-12.26l-3.832-47.288 16.094.001-.002 47.287z"
      />
      <path
        d="M571.411 611.446l-39.53-.002v-.5a15.386 15.386 0 0115.386-15.386h24.145z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M705.285 599.561h-12.26l-5.832-47.288 18.094.001-.002 47.287z"
      />
      <path
        d="M708.411 611.446l-39.53-.002v-.5a15.386 15.386 0 0115.386-15.386h24.145z"
        fill="#2f2e41"
      />
      <path
        d="M664.974 453.376a11.291 11.291 0 00.291-14.302 9.3 9.3 0 00-1.07-1.091l9.233-34.092-15.39 5.106-6.41 31.591a11.372 11.372 0 001.018 12.45 7.899 7.899 0 0012.328.338z"
        fill="#ffb8b8"
      />
      <path
        d="M664.381 432.404a3.909 3.909 0 01-.576-.043l-8.02-1.21a4.343 4.343 0 01-3.012-2.087 5.603 5.603 0 01-.703-3.872l10.005-55.882-6.786-35.901c-1.308-6.91 2.067-13.888 7.851-16.232a10.728 10.728 0 0110.494 1.344 14.317 14.317 0 015.62 10.208l4.803 45.777-.057.199-15.457 54.249a4.476 4.476 0 01-4.162 3.45z"
        fill="#6EB6FF"
      />
      <circle cx={662.013} cy={272.831} r={24.561} fill="#ffb8b8" />
      <path
        d="M649.438 387.001a4.971 4.971 0 01-1.79-.333l-38.82-14.931a4.983 4.983 0 01-3.161-5.328l1.56-11.693a26.501 26.501 0 016.249-23.59l.945-1.04 11.485-20.944a12.26 12.26 0 0116.731-4.807l20.282 11.334a12.238 12.238 0 015.65 6.825 37.406 37.406 0 01-.572 25.196l-13.887 36.106a5.035 5.035 0 01-4.672 3.205z"
        fill="#6EB6FF"
      />
      <path
        d="M547.7 587.95l-.053-.01a5 5 0 01-3.921-6.06l29.748-130.039a350.68 350.68 0 0120.102-60.146l12.657-28.856 47.226 21.56 6.743 12.332a120.697 120.697 0 017.976 43.023l47.987 140.632a5 5 0 01-3.91 6.546l-26.27-.297a4.045 4.045 0 01-.75.064 4.967 4.967 0 01-4.621-3.135l-54.855-142.186a1.006 1.006 0 00-.962-.62.97.97 0 00-.902.657L571.847 584.31a4.976 4.976 0 01-5.588 3.21z"
        fill="#2f2e41"
      />
      <path
        d="M583.614 428.035a10.056 10.056 0 005.365-14.456l23.523-26.9-18.4-2.51-19.517 25.93a10.11 10.11 0 009.029 17.936z"
        fill="#ffb8b8"
      />
      <path
        d="M594.702 409.084a5.009 5.009 0 01-2.347-.585l-8.278-4.415a5 5 0 01-1.869-7.092l30.575-48.177 5.301-36.356a14.035 14.035 0 1127.522 5.356l-10.946 44.81-36.064 44.605a4.984 4.984 0 01-3.894 1.854z"
        fill="#6EB6FF"
      />
      <path
        d="M639.906 252.566c2.84-9.316 19.254-13.473 32.898-9.607s23.444 14 25.725 23.908c5.053 21.943-21.3 39.751-16.525 61.696 3.16 14.519 21.436 28.246 42.02 31.561-22.684 4.747-50.373.575-71.41-10.759-13.092-7.054-24.076-17.699-23.91-28.435.1-6.501 4.235-12.25 9.293-17.216s11.11-9.381 15.752-14.565 7.856-11.391 6.502-17.972-8.235-13.443-17.494-15.358z"
        fill="#2f2e41"
      />
      <path
        d="M776.697 613.285h-381a1 1 0 010-2h381a1 1 0 010 2z"
        fill="#ccc"
      />
    </svg>
  );
}

export default FormImage2;
