import { url } from "../data/base-url";

export const clientfilter = async (filters, jobsPerPage) => {
  const clientEndpoint = "/jobs/filter";
  const AllJobs = [];
  const data = [];

  const clientResponse = await fetch(`${url}${clientEndpoint}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(filters),
  });

  const client = await clientResponse.json();

  // eslint-disable-next-line array-callback-return
  client.message.map((job) => {
    const val = {
      jobtype: "Direct Client",
      id: job._id,
      title: job.jobTitle,
      location: {
        city: job.city,
        country: job.country,
      },
      description: job.description,
      // salary:job.from+'-'+job.to+'€',
      jobcode: job.jobCode,
      applied: true,
    };
    AllJobs.push(val);
  });

  for (let i = 1; i <= Math.ceil(AllJobs.length / jobsPerPage); i++) {
    const arr = AllJobs.slice(i * jobsPerPage - jobsPerPage, i * jobsPerPage);
    data.push(arr);
  }

  // console.log(data.length,AllJobs.length)
  return data;
};

export const partnerfilter = async (filters, jobsPerPage) => {
  // console.log(filters,jobsPerPage)
  const partnerEndpoint = "/jobs/partnerjobsfilter";
  const AllJobs = [];
  const data = [];

  const partnerResponse = await fetch(`${url}${partnerEndpoint}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(filters),
  });

  const partner = await partnerResponse.json();

  // eslint-disable-next-line array-callback-return
  partner.message.map((job) => {
    const val = {
      jobtype: "Partner Job",
      id: job._id,
      title: job.jobTitle,
      location: {
        city: job.city,
        country: job.country,
      },
      description: job.description,
      jobcode: job.jobCode,
      link: job.recommendationLink,
    };
    AllJobs.push(val);
  });

  // console.log(AllJobs.length);
  for (let i = 1; i <= Math.ceil(AllJobs.length / jobsPerPage); i++) {
    const arr = AllJobs.slice(i * jobsPerPage - jobsPerPage, i * jobsPerPage);
    data.push(arr);
  }

  // console.log(data.length,AllJobs.length)
  return data;
};

export const Matched = async (id) => {
  const matchEndpoint = "/jobs";
  const MatchedResponse = await fetch(`${url}${matchEndpoint}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(id),
  });

  const Matched = await MatchedResponse.json();

  const sugg = Matched.message.suggestions;
  const client = sugg.client;

  // console.log(client);
  const jobs = [...new Set(client)];

  return jobs;
};

// export const jobs=async(jobsPerPage)=>{
//   const clientEndpoint='/jobs/allclientjobs';
//   const partnerEndpoint='/jobs/getpartnerjobs';
//   const AllJobs=[];
//   const data=[]
//   const clientResponse = await fetch(`${url}${clientEndpoint}`, {
// 		method:'POST',
// 		headers: { "Content-Type": "application/json" },
// 	});
//   const partnerResponse = await fetch(`${url}${partnerEndpoint}`, {
// 		method:'POST',
// 		headers: { "Content-Type": "application/json" },
// 	});
//   const client=await clientResponse.json();
//   const partner=await partnerResponse.json();

//   // eslint-disable-next-line array-callback-return
//   client.data.map((job)=>{
//     const val={
//       jobtype:"Direct Client",
//       id:job._id,
//       title:job.jobTitle,
//       location:{
//           city:job.city,
//           country:job.country,
//       },
//       description:job.description,
//       // salary:job.from+'-'+job.to+'€',
//       jobcode:job.jobCode,
//       applied:true
//     }
//     AllJobs.push(val);
//   })

//   // eslint-disable-next-line array-callback-return
//   partner.message.map((job)=>{
//     const val={
//       jobtype:"Partner Job",
//       id:job._id,
//       title:job.jobTitle,
//       location:{
//           city:job.city,
//           country:job.country,
//       },
//       description:job.description,
//       jobcode:job.jobCode,
//       link:job.recommendationLink,
//     }
//     AllJobs.push(val)
//   })

//   // console.log(AllJobs.length);
//   for(let i=1;i<=Math.ceil(AllJobs.length/jobsPerPage);i++){
//     let arr=AllJobs.slice(i*jobsPerPage-jobsPerPage,i*jobsPerPage);
//     data.push(arr)
//   }

//   // console.log(data.length,AllJobs.length)
//   return data
// }
